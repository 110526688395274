@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800;900&family=Carattere&family=Cardo:ital,wght@0,400;0,700;1,400&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
html{font-size:62.5%;height:100%;}
body{font-family:'Lora', serif;font-weight:400;font-style:normal;box-sizing:border-box;position:relative;font-size:1.4rem;line-height:26px;background-color:#ffffff;}
a, a:hover, a:focus, a:active{text-decoration:none;outline:0;}
input, input:focus, input:hover, input:active{outline:0;box-shadow:none;}
ul{margin:0 auto;padding:0;list-style:none;}
li{line-height:normal;}
button, button:hover, button:active, button:focus{box-shadow:none;outline:0;border:0;}

#preloader{position:fixed;left:0;top:0;width:100%;height:100%;background-color:#FDE8E8;display:flex;justify-content:center;align-items:center;z-index:99999;overflow:hidden;transition:all 1s ease-in-out;}
#preloader.hide{opacity:0;visibility:hidden;width:0%;}
#preloader .hide-loader{display:none;}
.preloader-inner{position:absolute;z-index:100;left:50%;top:50%;transform:translate(-50%, -50%);}
.preloader-inner .spinner{animation:spinner 1s infinite linear;border-radius:50%;border:3px solid #ffffff;border-top-color:#000000;width:60px;height:60px;margin:0 auto 3.5em auto;}
.preloader-inner .loading-text{font-weight:700;font-size:3rem;line-height:60px;text-align:center;user-select:none;transition:all 0.5s ease-in-out;}
#preloader.hide .preloader-inner .loading-text{opacity:0;visibility:hidden;}
.preloader-inner .loading-text .charactersSpace{display:inline-block;margin:0 6px;}
.preloader-inner .loading-text .characters{position:relative;display:inline-block;color:rgba(255, 255, 255, 0.2);}
.preloader-inner .loading-text .characters:before{content:attr(data-preloader-text);position:absolute;left:0;top:0px;opacity:0;transform:rotateY(-90deg);color:#000000;animation:characters 4s infinite;}
.preloader-inner .loading-text .characters:nth-child(2):before{animation-delay:0.1s;}
.preloader-inner .loading-text .characters:nth-child(3):before{animation-delay:0.2s;}
.preloader-inner .loading-text .characters:nth-child(4):before{animation-delay:0.3s;}
.preloader-inner .loading-text .characters:nth-child(5):before{animation-delay:0.4s;}
.preloader-inner .loading-text .characters:nth-child(6):before{animation-delay:0.5s;}
.preloader-inner .loading-text .characters:nth-child(7):before{animation-delay:0.6s;}
.preloader-inner .loading-text .characters:nth-child(8):before{animation-delay:0.7s;}
.preloader-inner .loading-text .characters:nth-child(9):before{animation-delay:0.8s;}
.preloader-inner .loading-text .characters:nth-child(10):before{animation-delay:0.9s;}
.preloader-inner .loading-text .characters:nth-child(11):before{animation-delay:1s;}
.preloader-inner .loading-text .characters:nth-child(12):before{animation-delay:1.1s;}
.preloader-inner .loading-text .characters:nth-child(13):before{animation-delay:1.2s;}
.preloader-inner .loading-text .characters:nth-child(14):before{animation-delay:1.3s;}
.preloader-inner .loading-text .characters:nth-child(15):before{animation-delay:1.4s;}
.preloader-inner .loading-text .characters:nth-child(16):before{animation-delay:1.5s;}
.preloader-inner .loading-text .characters:nth-child(17):before{animation-delay:1.6s;}
.preloader-inner .loading-text .characters:nth-child(18):before{animation-delay:1.7s;}
.preloader-inner .loading-text .characters:nth-child(19):before{animation-delay:1.8s;}

@keyframes spinner{
  to{transform:rotateZ(360deg);}
}
@keyframes characters{
  0%,
  75%,
  100%{opacity:0;transform:rotateY(-90deg);}
  25%,
  50%{opacity:1;transform:rotateY(0deg);}
}

.headerWrap{position:relative;}
.headerWrap .navbar{height:156px;padding:10px 0;align-items:center;}
.headerWrap .navbar-brand{width:420px;}
.headerWrap .navbar-brand img{width:100%;}
.navbar-expand-md .navbar-nav .nav-item{margin:0 15px!important;}
.navbar-expand-md .navbar-nav .nav-link{padding:0!important;color:#000000;font-size:1.6rem;font-family:'Quicksand', sans-serif;font-weight:500;}
.navbar-expand-md .navbar-nav .nav-link.active{font-weight:600;}
.navbar-expand-md .navbar-nav .nav-link .fa-chevron-down{font-size:1.3rem;margin:0 0 0 3px;display:inline-block;}
ul.rightNavShop{position:absolute;right:70px;top:25px;display:flex;align-items:center;flex-flow:row wrap;}
ul.rightNavShop li{margin:0 0 0 12px;}
ul.rightNavShop li a.btnnav{background-color:#161616;display:inline-block;color:#ffffff;padding:8px 16px;font-size:1.4rem;}
ul.rightNavShop li a.search{color:#333333;font-size:1.6rem;}
.headerWrap .offcanvas-body{height:100%;}
.headerWrap .navbar-nav{align-items:center;}
.headerWrap .navbar-nav .dropdown-menu{border-radius:0;box-shadow:0px 4px 4px rgba(0,0,0,0.1);background-color:#ffffff;min-width:140px;border:0;border-bottom:6px solid #FDE8E8;padding:0;}
.headerWrap .navbar-nav .dropdown-menu .dropdown-item{color:#000000;font-size:1.3rem;padding:10px;border-bottom:1px solid #f5f5f5;font-family:'Quicksand', sans-serif;font-weight:500;}
.headerWrap .navbar-nav .dropdown-menu .dropdown-item:hover,
.headerWrap .navbar-nav .dropdown-menu .dropdown-item:focus,
.headerWrap .navbar-nav .dropdown-menu  .dropdown-item.active, 
.headerWrap .navbar-nav .dropdown-menu  .dropdown-item:active{background-color:#FDE8E8;}

.topVector,
.BtmVector{position:absolute;width:100%;display:block;}
.topVector:before,
.BtmVector:before{content:"";width:36px;height:36px;position:absolute;background-color:#ffffff;}
.topVector:after,
.BtmVector:after{content:"";width:22px;height:22px;position:absolute;background-color:#ffffff;}
.topVector:before{top:-30px;left:0px;}
.topVector:after{left:36px;top:6px;}
.BtmVector:before{right:22px;bottom:-18px;}
.BtmVector:after{right:0;bottom:-40px;}
.titleWrap{text-align:center;}
.titleWrap h2{color:#161616;font-weight:700;font-size:3rem;line-height:4.2rem;}
.titleWrap p{color:#161616;margin:0 auto;font-size:1.4rem;line-height:2.6rem;}
.posRel{position:relative;}

.homeBannerWrap{width:100%;margin:0 auto;padding:40px 70px 60px;background-image:url(../img/Vector.png);background-repeat:no-repeat;background-position:center;background-size:100%;}
.homeBannerWrap .padlt0{padding-left:0;}
.homeBannerWrap .padrt0{padding-right:0;}
.homeBannerWrap .pad0{padding:0;}
.homeBannerWrap .dataWrap{background-color: #FDE8E8;border:8px solid #ffffff;padding:30px;text-align:left;position:absolute;top:46px;right:-40px;z-index:9;max-width:365px;}
.homeBannerWrap .dataWrap .descripWrap>p{overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;height:100px;font-family:'Lora', serif!important;background:transparent!important;text-align:left!important;}
.homeBannerWrap .dataWrap .descripWrap>p span,
.homeBannerWrap .dataWrap .descripWrap>p p,
.homeBannerWrap .dataWrap .descripWrap>p p span{font-family:'Lora', serif!important;}
.homeBannerWrap .dataWrap .descripWrap p br{display:none!important;}
.homeBannerWrap .dataWrap .descripWrap p:empty{display:none!important;}
.homeBannerWrap .dataWrap h2{color:#161616;font-size:2.6rem;margin:0;font-weight:700;line-height:3.8rem;}
.homeBannerWrap .dataWrap p{font-size:1.4rem!important;color:#161616!important;margin:25px 0 70px!important;line-height:2.6rem!important;font-family:'Lora', serif!important;background:transparent!important;}
.homeBannerWrap .dataWrap p a,
.homeBannerWrap .dataWrap p p,
.homeBannerWrap .dataWrap p strong{color:#161616!important;text-align:left!important;}
.homeBannerWrap .dataWrap .btnRead{background-color:#161616;padding:12px 24px;color:#ffffff;font-size:1.4rem;line-height:2.6rem;}
.homeBannerWrap .imgWrap{width:650px;height:676px;overflow:hidden;}
.homeBannerWrap .imgWrap img{width:100%;border:8px solid #ffffff;height:100%;object-fit:cover;object-position:center right;}
.homeBannerWrap .rightImgWrap{position:absolute;left:-42px;bottom:42px;width:312px;height:351px;}
.homeBannerWrap .rightImgWrap img{width:100%;border:8px solid #ffffff;height:100%;object-fit:cover;object-position:center right;}

.partnersWrap{width:100%;padding:0 70px 80px;margin:0 auto;background-color:#ffffff;}
.partnersListing{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;padding:30px 0 60px;}
.partnersListing li{display:inline-block;margin:0 16px;width:12.6%;}
.partnersListing li .imgWrap{display:inline-block;}
.partnersListing li .imgWrap img{filter:grayscale(100%);opacity:0.4;}
/* .partnersListing li:hover .imgWrap img{filter:grayscale(0%);opacity:1;} */
.partnersListing li:hover .imgWrap img{filter:sepia(100%) saturate(9000%) hue-rotate(330deg) brightness(70%) drop-shadow(0px 0px 5px rgba(253, 232, 232, 0.5));filter: sepia(100%) saturate(9000%) hue-rotate(330deg) brightness(70%) drop-shadow(0px 0px 5px rgba(253, 232, 232, 0.5)) contrast(100%) color-interpolation-filters(#FDE8E8);opacity:0.2;}
.padRightInc{padding-right:35px;}
.padLeft0{padding-left:0;}
.partnersWrap .itemWrap{position:relative;}
/* .partnersWrap .itemWrap.marBtm{margin-bottom:35px;} */
.partnersWrap .itemWrap.marBtm{margin-bottom:32px;}
.partnersWrap .itemWrap.imgRightWrap .imgWrap{height:220px;overflow:hidden;}
.partnersWrap .itemWrap .dataWrap{text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.partnersWrap .itemWrap .dataWrap h2{font-family:'Be Vietnam Pro', sans-serif;color:#FFFFFF;font-size:1.6rem;margin:0 auto;display:inline-block;letter-spacing:1.4px;text-transform:uppercase;font-weight:900;position:relative;z-index:9;padding:0 10px;line-height:2.8rem;}
.partnersWrap .itemWrap .dataWrap h2:after{content:"";background-color:#000000;width: 100%;height:14px;position:absolute;left:0px;bottom:-4px;z-index:-1;}

.pickUpWrap{width:100%;margin:0 auto;padding:80px 120px 0px;background-color:#ffffff;}
.pickUpFilter{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;padding:20px 0;border:0;}
.pickUpFilter li{margin:0 8px 16px;}
.pickUpFilter li a,
.pickUpFilter li .nav-link{background-color:#F1F1F1;padding:12px 24px;color:#333333;font-size:1.2rem;font-weight:500;text-transform:uppercase;display:inline-block;border:0;border-radius:0;outline:0;}
.pickUpFilter li .nav-link.active,
.pickUpFilter li:hover a,
.pickUpFilter li.active a{color:#ffffff;background-color:#161616;}

ul.pickUpSlider{outline:0;padding:0 50px;display:flex;align-content:center;justify-content:space-between;}
ul.pickUpSlider li{margin:0 auto;outline:0;display:inline-block;}
ul.pickUpSlider li .itemWrap{margin:0 auto;}
ul.pickUpSlider li.pickItem{margin:40px auto 0;}
ul.pickUpSlider li .itemWrap .imgWrap{text-align:center;}
ul.pickUpSlider li .itemWrap .imgWrap img{text-align:center;margin:0 auto;}
ul.pickUpSlider li .itemWrap .dataWrap{padding:10px 0 0 25px;}
ul.pickUpSlider li .itemWrap .dataWrap h3{color:#161616;font-size:1.4rem;font-weight:500;margin:0;line-height:2.6rem;}
ul.pickUpSlider .slick-arrow{background-color:transparent;width:50px;height:50px;border-radius:100%;box-shadow:0px 1px 16px rgba(0, 0, 0, 0.16);color:transparent;position:absolute;top:50%;transform:translateY(-50%);}
ul.pickUpSlider .slick-arrow:after{content:"";position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-image:url(../img/arrowIco.png);background-position:center;background-repeat:no-repeat;width:20px;height:20px;}
ul.pickUpSlider .slick-prev{left:-20px;}
ul.pickUpSlider .slick-next{right:-20px;}
ul.pickUpSlider .slick-next:after{transform:translate(-50%, -50%) rotate(-180deg);}

.fashionWrap{width:100%;background-color:#ffffff;margin:0 auto;padding:0 140px 70px;position:relative;}
.fashionWrap:after{content:"";background-color:#FDE8E8;width:calc(100% - 140px);height:190px;position:absolute;bottom:0;left:70px;}
.fashionWrap .padlt0{padding-left:0;}
.fashionWrap .imagesWrap{display:flex;align-items:flex-end;position:relative;z-index:9;}
.fashionWrap .imagesWrap .topImg{width:405px;height:540px;overflow:hidden;border:8px solid #ffffff;}
.fashionWrap .imagesWrap .topImg img{width:100%;height:100%;object-fit:cover;}
.fashionWrap .imagesWrap .btmImg{margin:0 0 -45px -45px;width:205px;height:267px;overflow:hidden;border:8px solid #ffffff;}
.fashionWrap .imagesWrap .btmImg img{width:100%;height:100%;object-fit:cover;}
.fashionWrap .fasionContent{padding:40px 0 0;position:relative;z-index:9;}
.fashionWrap .fasionContent span{color:#161616;font-size:1.2rem;letter-spacing:1.2px;text-transform:uppercase;font-family:'Be Vietnam Pro', sans-serif;}
.fashionWrap .fasionContent h2{font-size:3.4rem;color:#161616;font-weight:700;margin:10px 0 15px;line-height:4.2rem;}
.fashionWrap .fasionContent p{font-size:1.4rem!important;color:#161616!important;margin:0 0 20px;line-height:2.6rem!important;font-family:'Lora', serif!important;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;height:50px;}
.fashionWrap .fasionContent .btnRead{display:inline-block;background-color:#161616;color:#ffffff;font-size:1.4rem;padding:12px 24px;line-height:2.6rem;}
.fashionWrap .instaFeeds{position:relative;z-index:9;padding:0 0 0 36px;text-align:center;margin:30px 0 0;}
.fashionWrap .instaFeeds h3{color:#161616;font-size:2rem;font-weight:600;line-height:3.2rem;}
.fashionWrap .instaFeeds h3 span,
.fashionWrap .instaFeeds h3 strong{font-family:'Carattere', cursive;font-weight:400;}
.fashionWrap .instaFeeds p{margin:0 auto;text-align:center;position:absolute;left:50%;transform:translateX(-50%);width:100%;bottom:-64px;color:#161616;font-size:1.4rem;text-transform:uppercase;font-weight:500;line-height:2.6rem;}
.fashionWrap .instaFeeds p a{color:#161616;text-transform:none;}
.fashionWrap .instaFeeds ul{background-color:#ffffff;padding:15px;display:flex;align-items:flex-end;justify-content:center;}
.fashionWrap .instaFeeds ul li{height:135px;display:inline-block;max-width:100%;width:100%;}
.fashionWrap .instaFeeds ul li a{display:flex;height:100%;align-items:flex-end;justify-content:center;}
ul.instaFeedSlidr .slick-arrow{background-color:#ffffff;width:28px;height:28px;border-radius:100%;box-shadow:0px 1px 16px rgba(0, 0, 0, 0.16);color:transparent;position:absolute;bottom:-40px;}
ul.instaFeedSlidr .slick-arrow:after{content:"";position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-image:url(../img/arrowIco.png);background-position:center;background-repeat:no-repeat;width:12px;height:12px;background-size:contain;}
ul.instaFeedSlidr .slick-prev{left:42%;}
ul.instaFeedSlidr .slick-next{right:45%;}
ul.instaFeedSlidr .slick-next:after{transform:translate(-50%, -50%) rotate(-180deg);}

.livingWrap{width:100%;padding:80px 70px 0px 70px;background-color:#ffffff;margin:0 auto -20px;}
.livingWrap .pad0px{padding:0;}
.livingWrap .leftPanel{position:relative;z-index:9;}
.livingWrap .leftPanel:after{content:"";background-color:#FDE8E8;width:100%;height:162px;position:absolute;bottom:-26px;left:0;z-index:-1;}
.livingWrap .leftPanel .topImgsWrap{display:flex;align-items:flex-start;}
.livingWrap .leftPanel .topImgsWrap .img1{margin:90px 0 0 0;width:302px;height:368px;overflow:hidden;}
.livingWrap .leftPanel .topImgsWrap .img1 img{border-radius:0 0  0 150px;width:100%;height:100%;object-fit:cover;}
.livingWrap .leftPanel .topImgsWrap .img2{margin:0 0 0 -90px;width:194px;height:242px;overflow:hidden;}
.livingWrap .leftPanel .topImgsWrap .img2 img{border-radius:0 110px;width:100%;height:100%;object-fit:cover;}
.livingWrap .leftPanel  .socialShareWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:flex-start;padding:30px 0 0 30px;}
.livingWrap .leftPanel  .socialShareWrap h4{color:#161616;margin:0;font-size:1.2rem;text-transform:uppercase;font-family:'Be Vietnam Pro', sans-serif;}
.livingWrap .leftPanel  .socialShareWrap ul{display:flex;align-items:center;justify-content:flex-start;margin:0;flex-flow:row wrap;}
.livingWrap .leftPanel  .socialShareWrap ul li{margin:0 5px;}
.livingWrap .leftPanel  .socialShareWrap ul li a{color:#333333;font-size:1.6rem;line-height:2.8rem;}
.livingWrap .leftPanel  .socialShareWrap ul li a svg{margin:-4px 0 0}
.livingWrap .topDataWrap span{color:#161616;font-size:1.2rem;letter-spacing:1.2px;text-transform:uppercase;font-family:'Be Vietnam Pro', sans-serif;}
.livingWrap .topDataWrap h2{font-size:3.4rem;color:#161616;font-weight:700;margin:10px 0 15px;line-height:4.2rem;}
.livingWrap .topDataWrap p{font-size:1.4rem!important;color:#161616!important;margin:0 0 20px;line-height:2.6rem;font-family:'Lora', serif!important;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;height:80px;}
.livingWrap .topDataWrap .btnRead{display:inline-block;background-color:#161616;color:#ffffff;font-size:1.4rem;padding:12px 24px;line-height:2.6rem;}
.livingWrap .americalEgalWrap{padding:28px 0 0;margin:35px 0 0;border-top:1px solid #E0E0E0;}
.livingWrap .americalEgalWrap h3{color:#161616;font-size:2rem;font-weight:600;margin:0 0 10px;text-align:center;line-height:3.2rem;}
.livingWrap .americalEgalWrap h3 span{font-family:'Carattere', cursive;font-weight:400;}
.livingWrap .americalEgalWrap ul{background-color:#ffffff;padding:15px;display:flex;align-items:flex-end;justify-content:space-between;}
ul.americalEgalSlider li{display:inline-block;max-width:100%;width:100%;}
ul.americalEgalSlider .slick-arrow{background-color:#ffffff;width:28px;height:28px;border-radius:100%;box-shadow:0px 1px 16px rgba(0, 0, 0, 0.16);color:transparent;position:absolute;top:-40px;}
ul.americalEgalSlider .slick-arrow:after{content:"";position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-image:url(../img/arrowIco.png);background-position:center;background-repeat:no-repeat;width:12px;height:12px;background-size:contain;}
ul.americalEgalSlider .slick-prev{right:32px;}
ul.americalEgalSlider .slick-next{right:0px;}
ul.americalEgalSlider .slick-next:after{transform:translate(-50%, -50%) rotate(-180deg);}
.oldNavyWrap{text-align:center;margin:0 auto;}
.oldNavyWrap .imgWrap{position:relative;z-index:9;}
.oldNavyWrap h2{color:#161616;font-size:2rem;font-weight:600;margin:0 auto 20px;line-height:3.2rem;}
.oldNavyWrap h2 span{font-family:'Carattere', cursive;font-weight:400;}
.oldNavyWrap .emailDataWrap{margin:25px auto 0;}
.oldNavyWrap .emailDataWrap .icoWrap{position:relative;z-index:9;}
.oldNavyWrap .emailDataWrap .dataWrap h3{font-family:'Cardo', serif;font-style:italic;color:#161616;font-size:2rem;font-weight:400;line-height:26px;max-width:72%;margin:15px auto 10px;position:relative;z-index:9;}
.oldNavyWrap .emailDataWrap .dataWrap .inpuFieldWrap{text-align:center;}
.oldNavyWrap .emailDataWrap .dataWrap .inpuFieldWrap input{width:100%;padding:5px;height:42px;border:0;border-bottom:1px solid #E0E0E0;box-shadow:none;outline:0;border-radius:0;color:#161616;font-size:1.3rem;font-family:'Be Vietnam Pro', sans-serif;font-weight:400;text-align:center;}
.oldNavyWrap .emailDataWrap .dataWrap .inpuFieldWrap button{outline:0;border-radius:0;box-shadow:none;background-color:#161616;padding:12px 24px;color:#ffffff;font-size:1.4rem;margin:15px auto 0;}
.oldNavyWrap .dataWrap iframe .signupFormContainer #zcampaignOptinForm div:nth-of-type(4){margin:0 auto!important;width:100%!important;}


.trueStoryWrap{width:100%;background-color:#ffffff;margin:0 auto;padding:60px 70px 0;}
.trueStoryCotnent{background-color:#FDE8E8;width:100%;padding:60px 40px 60px 60px;position:relative;}
.trueStoryWrap .topVector:before{top:-60px;left:-60px;}
.trueStoryWrap .topVector:after{top:-25px;left:-25px;}
.trueStoryWrap .BtmVector:before{right:82px;bottom:-40px;}
.trueStoryWrap .BtmVector:after{bottom:-60px;right:60px;}
.trueStoryCotnent .dataWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-end;}
.trueStoryCotnent .dataWrap .leftData{width:calc(100% - 0px);}
.trueStoryCotnent .dataWrap .leftData span{color:#161616;display:inline-block;font-family:'Be Vietnam Pro', sans-serif;font-size:1.2rem;text-transform:uppercase;font-weight:500;letter-spacing:1.4px;}
.trueStoryCotnent .dataWrap .leftData p{color:#000000;font-size:1.6rem;line-height:28px;margin:45px 0 55px;}
.trueStoryCotnent .dataWrap .leftData h4{color:#161616;font-size:2.4rem;margin:0;font-weight:600;}
.trueStoryCotnent .dataWrap .leftData h4 span{font-size:1.2rem;font-weight:400;font-family:'Be Vietnam Pro', sans-serif;text-transform:none;letter-spacing:0;}
.trueStoryCotnent .dataWrap .rightImg{/*width:76px;margin:0 0 -40px;*/width:76px;margin:0;position:absolute;right:60px;bottom:60px;}
.trueStoryCotnent .imgWrap{/*width:348px;height:418px;*/overflow:hidden;}
.trueStoryCotnent .imgWrap img{width:100%;height:100%;object-fit:cover;object-position:top;}

.blogCatWrap{width:100%;margin:0 auto;background-image:url(../img/Vector.png);background-repeat:no-repeat;padding:60px 70px 50px;background-size:100%;background-position:center;}
.blogCatBox{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.blogCatBox .leftData{width:44.55%;}
.blogCatBox .rightData{width:calc(100% - 47%);}
.blogCatWrap .leftImgWrap{text-align:center;position:relative;}
.blogCatWrap .leftImgWrap .imgWrap{width:100%;overflow:hidden;border:10px solid #ffffff;height:484px;}
.blogCatWrap .leftImgWrap .imgWrap img{width:100%;height:100%;object-fit:cover;}
.blogCatWrap .leftImgWrap .playBtn{color:#000000;width:56px;height:56px;background-color:#ffffff;border-radius:100%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);font-size:1.8rem;display:flex;align-items:center;justify-content:center;}
.blogCatWrap .leftImgWrap  .dataWrap h3{color:#000000;font-size:2rem;font-weight:400;text-transform:uppercase;margin:6px auto 0;line-height:3.2rem;}
.blogCatWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.blogCatWrap ul li{width:48%;margin:0 0 25px;}
.blogCatWrap ul li a{color:#000000;}
.blogCatWrap ul li .itemWrap{text-align:center;}
.blogCatWrap ul li .itemWrap .imgWrap img{width:100%;}
.blogCatWrap ul li .dataWrap h3{color:#000000;font-size:1.6rem;font-weight:400;text-transform:uppercase;margin:10px auto 0;line-height:2.8rem;} 
.blogCatWrap .ctaWrap{text-align:center;padding:70px 0 0;}
.blogCatWrap .ctaWrap a{background-color:#161616;color:#ffffff;font-size:1.4rem;padding:12px 24px;display:inline-block;line-height:2.6rem;}
.blogCatBox .rightData ul li .itemWrap .imgWrap{width:100%;height:211px;border:10px solid #ffffff;overflow:hidden;}
.blogCatBox .rightData ul li .itemWrap .imgWrap img{object-fit:cover;width:100%;height:100%;}

.blogCatDtlWrap{width:100%;margin:30px auto 0;background:linear-gradient(to left, #FFF6F6 66%, #FFE5E5 34%);}
.blogCatDtlWrap .blogCatList{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:flex-start;margin:0 -15px;padding:45px 0 0;}
.blogCatDtlWrap .blogCatList li{width:25%;padding:0 15px;text-align:center;}
.blogCatDtlWrap .blogCatList li:nth-child(even){margin:-35px 0 0;}
.blogCatDtlWrap .blogCatList .imgWrap{height:200px;overflow:hidden;border:8px solid #ffffff;}
.blogCatDtlWrap .blogCatList .imgWrap img{width:100%;height:100%;object-fit:cover;}

.blogWrap{width:100%;padding:60px 0;margin:0 auto;background-color:#ffffff;}
.latestPostWrap{width:100%;padding:60px 70px;margin:0 auto;background-color:#ffffff;position:relative;z-index:9;}
.latestPostWrap .filterList{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;padding:25px 0 0;}
.latestPostWrap .filterList li{margin:0 6px 12px;}
.latestPostWrap .filterList li .btnCat{background-color:#F1F1F1;padding:12px 24px;color:#333333;font-size:1.2rem;font-weight:500;text-transform:uppercase;display:inline-block;border:0;border-radius:0;outline:0;}
.latestPostWrap .filterList li:hover .btnCat{color:#ffffff;background-color:#161616;}
.lPostListing{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:flex-start;margin:0 -12px;padding:20px 0 0;}
.lPostListing li{width:25%;margin:0 0 30px;text-align:center;}
.shopProdListing li{width:16.66%;}
.lPostListing li .itemWrap{margin:0 12px;}
.lPostListing li .itemWrap .imgWrap{height:366px;width:100%;border:1px solid #f5f5f5;}
.lPostListing li .itemWrap .imgWrap img {width:100%;height:100%;object-fit:cover;object-position:top center;}
.lPostListing li .dataWrap{padding:15px;}
.lPostListing li .dataWrap span{color:#161616;font-size:1.1rem;letter-spacing:0.8px;text-transform:uppercase;font-family:'Be Vietnam Pro', sans-serif;font-weight:500;}
.lPostListing li .dataWrap h3{color:#333333;font-size:1.8rem;font-weight:700;margin:8px 0;line-height:3rem;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;}
.lPostListing li .dataWrap .descripWrap{overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;height:52px;}
.lPostListing li .dataWrap .descripWrap img,
.lPostListing li .dataWrap .descripWrap h4 img,
.lPostListing li .dataWrap .descripWrap p h4 img,
.lPostListing li .dataWrap .descripWrap p div h4 img{display:none!important;}
.lPostListing li .dataWrap .descripWrap p div h4,
.lPostListing li .dataWrap .descripWrap p,
.lPostListing li .dataWrap .descripWrap p p,
.lPostListing li .dataWrap .descripWrap p div,
.lPostListing li .dataWrap .descripWrap p div div,
.lPostListing li .dataWrap .descripWrap p span,
.lPostListing li .dataWrap .descripWrap p h3,
.lPostListing li .dataWrap .descripWrap p p h3{font-size:1.4rem!important;color:#161616!important;line-height:2.6rem!important;font-family:'Lora', serif!important;background-color:transparent!important;margin:0!important;border:0!important;text-align:center!important;font-weight:400!important;}
.lPostListing li .dataWrap .descripWrap>p{text-overflow:ellipsis;}
.lPostListing li .dataWrap .seePost{color:#161616;font-size:1.4rem;font-weight:600;position:relative;}
.lPostListing li .dataWrap .seePost:after{content:"";background-color:#161616;width:55px;height:1px;position:absolute;left:0;bottom:0;}
.lPostListing li .dataWrap .seePost .fa-chevron-right{color:#161616;font-size:1.2rem;margin:0 0 0 3px;}
.lPostListing li .dataWrap .descripWrap p div{margin:0 auto!important;padding:0!important;}
.lPostListing li .dataWrap .descripWrap p div:empty,
.lPostListing li .dataWrap .descripWrap p div div:empty,
.lPostListing li .dataWrap .descripWrap p div div div:empty{display:none!important;}
.subCatBlogWrap .lPostListing li,
.subCatBlogWrap .lPostListing li .dataWrap p,
.subCatBlogWrap .lPostListing li .dataWrap p p,
.subCatBlogWrap .lPostListing li .dataWrap p h3{text-align:left!important;}
.subCatBlogWrap .lPostListing li .dataWrap p a{font-size:1.4rem!important;}
.subCatBlogWrap .lPostListing li .dataWrap p p br{display:none;}


.instaFeedWrap{width:100%;margin:0 auto;padding:120px 70px 40px;background-color:#ffffff;position:relative;z-index:9;}
.instaFeedBox{background-color:#FDE8E8;padding:0 50px 20px;}
.instaFeedBox h2{margin:-130px auto 40px;text-align:center;color:#161616;font-size:2.4rem;line-height:3.2rem;}
.instaFeedBox h2 span{font-family:'Carattere', cursive;}
.instaFeedBox ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-end;background-color:#ffffff;margin-bottom:50px!important;}
.instaFeedBox ul.padRt{margin:0 18px 0 0;}
.instaFeedBox ul.padLt{margin:0 0 0 18px;}
.instaFeedBox ul.padRt li,
.instaFeedBox ul.padLt li{width:100%;max-width:1000%;}
.instaFeedBox p{text-align:center;color:#161616;text-transform:uppercase;margin:0 auto;font-size:1.4rem;font-weight:500;line-height:2.6rem;}
.instaFeedBox p a{color:#161616;text-transform:none;}

.favoritesWrap{width:100%;margin:0 auto;padding:60px 70px;background-color:#ffffff;}
.favoritesWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;padding:30px 0 0;width:calc(100% + 16px);}
.favoritesWrap ul li{width:25%;margin:0 0 15px;padding:0 8px;}
.favoritesWrap ul li .favItem .imgWrap img{height:302px;width:100%;object-fit:cover;}
.favoritesWrap ul li .favItem .imgData{text-align:center;padding:10px 15px;}
.favoritesWrap ul li .favItem .imgData span{font-family:'Be Vietnam Pro', sans-serif;color:#161616;font-size:1rem;letter-spacing:1.4px;font-weight:400;text-transform:uppercase;display:inline-block;margin:0 4px;}
.favoritesWrap ul li .favItem .imgData h3{margin:5px auto 0;color:#333333;font-size:1.4rem;font-weight:600;line-height:2.4rem;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;min-height:52px;}
.favoritesWrap ul li.quoteTxt .favDataDesc{height:399px;background-color:#FDE8E8;display:flex;align-items:center;justify-content:center;padding:30px 50px;text-align:center;}
.favoritesWrap ul li.quoteTxt .favDataDesc p{margin:0 auto;color:#995757;font-size:1.6rem;font-weight:700;line-height:24px;line-height:2.8rem;}
/* .favoritesWrap ul li:nth-child(1),
.favoritesWrap ul li.bigWid{width:33.33%;} */
.favoritesWrap ul li:nth-child(1) .topImgWrap{position:relative;}
.favoritesWrap ul li:nth-child(1) .topImgWrap img{height:399px;width:100%;object-fit:cover;}
.favoritesWrap ul li:nth-child(1) .topImgWrap .imgData{width:100%;position:absolute;padding:80px 22px 20px;text-align:center;bottom:0;background:linear-gradient(359deg, rgba(22, 22, 22, 0.78), rgba(23, 23, 23, 0.37) 54.11%, rgba(255, 255, 255, 0))}
.favoritesWrap ul li:nth-child(1) .topImgWrap .imgData span{color:#ffffff;letter-spacing:1.2px;font-size:1.1rem;font-weight:400;font-family:'Be Vietnam Pro', sans-serif;text-transform:uppercase;display:inline-block;margin:0 4px;}
.favoritesWrap ul li:nth-child(1) .topImgWrap .imgData h3{color:#ffffff;margin:4px auto 0;font-size:1.4rem;font-weight:500;line-height:20px;line-height:2.6rem;}
.favoritesWrap ul li .bgClrtxt{min-height:360px;background-color:#FDE8E8;height:100%;display:flex;align-items:center;justify-items:center;}
.favoritesWrap ul li .bgClrtxt .btmDataWrap{width:100%;text-align:center;max-width:250px;display:inline-block;margin:0 auto;}
/* .favoritesWrap ul li .bgClrtxt h3{color:#161616;font-weight:700;font-size:1.8rem;margin:0 auto;} */
.favoritesWrap ul li .bgClrtxt h3{margin:0 auto;color:#995757;font-size:1.6rem;font-weight:700;line-height:24px;line-height:2.8rem;}
.favoritesWrap ul li .bgClrtxt p{color:#333333;font-size:1.4rem;margin:20px auto;line-height:22px;line-height:2.6rem;}
.favoritesWrap ul li .bgClrtxt a{display:inline-block;background-color:#161616;color:#ffffff;font-size:1.4rem;padding:12px 24px;outline:0;line-height:2.6rem;}
.ctasWrap{text-align:center;padding:30px 0 0;}
.ctasWrap .ctaTxt{background-color:#161616;color:#ffffff;font-size:1.4rem;padding:12px 34px;display:inline-block;}

.blogDtlWrap{width:100%;margin:0 auto;padding:20px 70px 20px;background-color:#ffffff;}
.blogDtlWrap .tagList{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;margin:15px auto 20px;}
.blogDtlWrap .tagList li{margin:0 6px;}
.blogDtlWrap .tagList li a{background-color:#F1F1F1;padding:12px 24px;color:#333333;font-size:1.2rem;font-weight:500;display:inline-block;text-transform:capitalize;}
.blogDtlWrap .mainImgWrap{width:100%;height:400px;overflow:hidden;position:relative;z-index:9;}
.blogDtlWrap .mainImgWrap img{width:100%;height:100%;object-fit:cover;}
.blogDtlContent{padding:25px 0 0;}
.blogDtlContent .topData{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.blogDtlContent .socialIco{width:30px;position:relative;z-index:9;}
.blogDtlContent .socialIco ul li{margin:0 0 15px;}
.blogDtlContent .socialIco ul li button svg{width:24px;height:24px;}
.blogDtlContent .socialIco ul li button svg circle{fill:#333333;}
.blogDtlContent .socialIco ul li button svg path{fill:#ffffff;}
.blogDtlContent .dataWrap{width:calc(100% - 35px);position:relative;z-index:9;}
.blogDtlContent .dataWrap .shopthepost-widget{margin:18px auto;}
.blogDtlContent .dataWrap h2{color:#333333;font-size:2rem;font-weight:700;line-height:30px;line-height:3.2rem;text-align:left;}
.blogDtlContent .btmDataWrap{margin:15px 0 0;}
#blogDetails.blogDtlContent .dataWrap p{clear:both;}
#blogDetails.blogDtlContent .dataWrap p br{display:none!important;}
#blogDetails.blogDtlContent .dataWrap p:empty{display:none!important;}
.blogDtlContent .dataWrap p,
.blogDtlContent .btmDataWrap p,
.blogDtlContent .btmDataWrap p span,
.blogDtlContent .btmDataWrap p p span,
.blogDtlContent .dataWrap p,
.blogDtlContent .btmDataWrap p,
.blogDtlContent .dataWrap p span,
.blogDtlContent .dataWrap p p span,
#blogDetails.blogDtlContent .dataWrap p,
#blogDetails.blogDtlContent .dataWrap p span,
#blogDetails.blogDtlContent .dataWrap p span#isPasted,
#blogDetails.blogDtlContent .dataWrap p p span{font-size:1.6rem;line-height:2.8rem!important;color:#474747;/*color:#828282!important;*/font-family:'Lora', serif;text-align:justify;}
#blogDetails.blogDtlContent .dataWrap p p a,
#blogDetails.blogDtlContent .dataWrap p p a strong,
#blogDetails.blogDtlContent .dataWrap p a strong{color:#ff9a9a;font-weight:600;}
#blogDetails.blogDtlContent .dataWrap .shopthepost-widget div.stp-info span.stp-price{text-align:center!important;color:#ffffff!important;z-index:999!important;position:relative;}
#blogDetails.blogDtlContent .dataWrap .shopthepost-widget{text-align:center!important;}
/* #blogDetails.blogDtlContent .dataWrap ul{padding:0!important;margin:0!important;} */
#blogDetails.blogDtlContent .dataWrap ul{list-style:inherit;padding:0 0 0 12px;}
#blogDetails.blogDtlContent .dataWrap ul li{font-size:1.6rem!important;line-height:2.8rem!important;color:#474747;/*color:#828282!important;font-family:'Lora', serif;text-align:left!important;*/margin:0 0 8px;position:relative;padding:0;}
/* #blogDetails.blogDtlContent .dataWrap ul li:before{content:"";background-color:#474747;width:6px;height:6px;border-radius:100%;position:absolute;left:0;top:10px;} */
#blogDetails.blogDtlContent .dataWrap img,
.blogDtlContent .dataWrap p img{margin:10px auto;text-align:center!important;display:block;}
.blogDtlContent .blogImgListing{position:relative;display:flex;flex-flow:row wrap;justify-content:center;margin:15px 0 0;}
/* .blogDtlContent .blogImgListing:before,
.blogDtlContent .blogImgListing:after{content:"";background-image:url(../img/Vector-1.png);background-repeat:no-repeat;width:112%;height:320px;position:absolute;background-size:100% 100%;background-position:center;left:-83px;}
.blogDtlContent .blogImgListing:before{top:90px;}
.blogDtlContent .blogImgListing:after{bottom:0;} */
.blogDtlContent .blogImgListing li{width:39.33%;z-index:9;position:relative;}
.blogDtlContent .blogImgListing li:nth-child(1),
.blogDtlContent .blogImgListing li:nth-child(4),
.blogDtlContent .blogImgListing li:nth-child(7){z-index:1;margin:30px -112px 0 0;}
.blogDtlContent .blogImgListing li:nth-child(2),
.blogDtlContent .blogImgListing li:nth-child(5),
.blogDtlContent .blogImgListing li:nth-child(8){z-index:2;margin:60px -70px 0 0;}
.blogDtlContent .blogImgListing li:nth-child(3),
.blogDtlContent .blogImgListing li:nth-child(6),
.blogDtlContent .blogImgListing li:nth-child(9){z-index:3;margin:30px 0 0 -5px;}

.blogDtlContent .blogImgListing .imgWrap{width:353px;height:379px;overflow:hidden;border:8px solid #FFFFFF;}
.blogDtlContent .blogImgListing .imgWrap img{width:100%;height:100%;object-fit:cover;}
.relatedBlogWrap{background-color:#FFEFEF;padding:22px 0 5px;margin:25px 0 0;position:relative;z-index:9;}
.relatedBlogWrap h2{color:#161616;font-size:2rem;font-weight:700;position:relative;padding:0 13px 6px;margin:0 0 10px;line-height:3.2rem;}
.relatedBlogWrap h2:after{content:"";background-color:#333333;width:60px;height:2px;border-radius:2px;position:absolute;left:13px;bottom:0;}
.relatedBlogWrap ul li{margin:0 0 20px;border-bottom:1px solid #ffdbdb;padding:0 0 20px;}
.relatedBlogWrap ul li:last-child{padding:0;border:0;}
.relatedBlogWrap ul li a{color:#333333;}
.relatedBlogWrap .imgWrap{padding:0px 13px 8px;height:312px;}
.relatedBlogWrap .imgWrap img{box-shadow:0px 4px 14px rgba(118, 55, 55, 0.3);height:100%;width:100%;object-fit:cover;}
.relatedBlogWrap .descriptionWrap p img{display:none!important;}
.relatedBlogWrap .dataWrap{padding:0px 13px 0;}
.relatedBlogWrap .dataWrap h3{color:#333333;font-size:1.6rem;font-weight:700;margin:0 0 6px;line-height:2.8rem;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;}
.relatedBlogWrap .dataWrap p,
.relatedBlogWrap .dataWrap p p,
.relatedBlogWrap .dataWrap p div,
.relatedBlogWrap .dataWrap p div div,
.relatedBlogWrap .dataWrap p p span,
.relatedBlogWrap .dataWrap p h3{color:#161616!important;font-size:1.2rem!important;line-height:1.8rem!important;margin:0!important;text-overflow:ellipsis!important;background-color:transparent!important;font-family:'Lora', serif!important;border:0!important;text-align:left!important;}
.relatedBlogWrap .dataWrap .descriptionWrap{overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;height:38px;}
.relatedBlogWrap .dataWrap .descriptionWrap>p{text-overflow:ellipsis;}
.relatedBlogWrap .dataWrap .descriptionWrap article{background-color:transparent!important;}

.aboutWrap{width:100%;margin:0 auto;padding:20px 70px 0;background-color:#ffffff;}
/*.aboutWrap .imgWrap2{padding:80px 0 0 ;}
.aboutWrap .topDataWrap{padding:120px 28px 10px;}
.aboutWrap .topDataWrap h4{font-style:italic;font-weight:600;font-size:1.8rem;line-height:3rem;}
.aboutWrap .btmDataWrap{background-color:#ffffff;padding:25px 20px 15px;position:absolute;left:-70px;bottom:50px;}
.aboutWrap .btmDataWrap p{color:#828282;font-size:1.6rem;line-height:2.8rem;} */
.aboutWrap .topDataWrap span{color:#333333;font-size:1.6rem;font-weight:500;position:relative;line-height:2.8rem;display:block;}
.aboutWrap .topDataWrap h2{color:#333333;font-size:3.2rem;line-height:3.2rem;position:relative;font-weight:600;display:inline-block;padding:0 200px 0 0;margin:10px 0 20px;text-transform:uppercase;}
.aboutWrap .topDataWrap h2:after{content:"";background-color:#333333;width:190px;height:1px;position:absolute;right:0px;top:50%;transform:translateY(-50%);}
/* .aboutWrap .topDataWrap h2:after{content:"";background-color:#333333;width:120px;height:1px;position:absolute;right:0px;top:50%;transform:translateY(-50%);} */
.aboutWrap .titleWrap{padding:0 0 28px;}
.aboutWrap .flexOr-1 .imgWrap{width:46%;float:left;margin:0 30px 10px 0;}
.aboutWrap .flexOr-1 .imgWrap img{width:100%;}
.aboutWrap .flexOr-1 .contentWrap{clear:right;}
.aboutWrap .btmDataWrap{padding:0 10px 0 0;}
.aboutWrap .btmDataWrap p{font-family:'Lora', serif!important;text-align:justify;}
/* .aboutWrap .imgWrap2{position:relative;top:72px;} */
.aboutWrap .imgWrap2{position:relative;top:0px;height:560px;overflow:hidden;}
.aboutWrap .imgWrap2 img{width:100%;height:100%;object-fit:cover;object-position:center top;}

.startWithBx1Wrap{width:100%;margin:-74px auto 0;padding:0px 70px 20px;background-color:#ffffff;/*position:relative;*/}
.startWithBx1Wrap .dataWrap{text-align:right;/*padding:0 20px 0 30px;*/}
.startWithBx1Wrap .abtTtl{display:inline-block;padding:0 12px 20px 200px;}
.startWithBx1Wrap .abtTtl h2{color:#333333;font-size:3.2rem;line-height:3.2rem;font-weight:600;margin:10px 0 0;position:relative;display:inline-block;padding:0 0 0 200px;text-transform:uppercase;}
.startWithBx1Wrap .abtTtl h2:after{content:"";background-color:#333333;width:190px;height:1px;position:absolute;left:0px;top:50%;transform:translateY(-50%);}
.startWithBx1Wrap .abtTtl span{color:#333333;font-size:1.6rem;font-weight:500;position:relative;line-height:2.8rem;display:block;}
/* .startWithBx1Wrap .abtTtl span:after{content:"";background-color:#333333;width:190px;height:1px;position:absolute;left:-240px;top:50%;transform:translateY(-50%);} */
.startWithBx1Wrap .dataWrap p,
.startWithBx1Wrap .dataWrap p p span{color:#828282;font-size:1.6rem;line-height:2.8rem;font-family:'Lora', serif!important;text-align:justify;}

.startWithBx2Wrap{width:100%;margin:0 auto;padding:0 70px;background-color:#ffffff;position:relative;}
.startWithBx2Wrap:after{content:"";background-color:#FFF6F6;width:100%;min-height:256px;position:absolute;left:0;bottom:0;}
.posRel{position:relative;z-index:9;}
.startWithBx2Wrap .abtTtl{position:relative;display:inline-block;padding:0 210px 0 0;margin:0 0 15px;}
/* .startWithBx2Wrap .abtTtl:after{content:"";height:2px;width:195px;background-color:#333333;position:absolute;right:0;top:50%;transform:translateY(-50%);} */
.startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 30px 50px 30px;}
.startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 80px;}
.startWithBx2Wrap .dataWrap h2{color:#333333;font-size:3.2rem;line-height:3.2rem;font-weight:600;margin:10px 0 0;position:relative;display:inline-block;padding:0 200px 0 0;text-transform:uppercase;}
.startWithBx2Wrap .dataWrap h2:after{content:"";background-color:#333333;width:190px;height:1px;position:absolute;right:0px;top:50%;transform:translateY(-50%);}
.startWithBx2Wrap .dataWrap span{color:#333333;font-size:1.6rem;font-weight:500;position:relative;line-height:2.8rem;display:block;}

.startWithBx2Wrap .dataWrap p,
.startWithBx2Wrap .dataWrap p p span{color:#828282;font-size:1.6rem;line-height:2.8rem;font-family:'Lora', serif!important;background-color:transparent!important;}
.startWithBx2Wrap .dataWrap.dataWrapBtm p,
.startWithBx2Wrap .dataWrap.dataWrapBtm p p span{font-size:1.4rem;line-height:2.6rem;}
.startWithBx2Wrap .imgWrap{width:400px;height:426px;overflow:hidden;}
.startWithBx2Wrap .imgWrap img{width:100%;height:100%;object-fit:cover;}

.misVisWrap{width:100%;margin:100px auto;background:linear-gradient(to left, #FFF6F6 50%, #ffffff 50%);padding:40px 70px;}
.misVisWrap .contentWrap1{padding:0 30px 0 0;}
.misVisWrap .contentWrap2{padding:0 0 0 30px;}
.misVisWrap .ttlWrap{margin:0 0 20px;}
.misVisWrap .ttlWrap h2{color:#333333;font-size:2.2rem;font-size:700;margin:0;}
.misVisWrap .dataWrap p{color:#828282;font-size:1.4rem;line-height:24px;margin:0 0 15px;}
.misVisWrap .dataWrap ul{padding:0;margin:0;}
.misVisWrap .dataWrap ul li{color:#828282;font-size:1.4rem;line-height:18px;position:relative;margin:0 0 15px;padding:0 0 0 30px;}
.misVisWrap .dataWrap ul li:after{content:"";background-image:url(../img/icoCheck.png);background-repeat:no-repeat;background-position:center;width:18px;height:18px;background-size:cover;position:absolute;left:0;top:2px;}

.contactPageWrap{width:100%;margin:0 auto;padding:30px 70px 60px;background-color:#ffffff;}
.contactWrap{box-shadow:0px 4px 44px rgba(0, 0, 0, 0.08);background-color:#ffffff;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;padding:15px;margin:20px auto;}
.contactDtlWrap{width:35%;background-color:#FFEFEF;padding:35px;}
.contactDtlWrap .topInfo{margin:0 0 20px;}
.contactDtlWrap .topInfo h2{color:#333333;font-size:2rem;font-weight:700;line-height:3.2rem;}
.contactDtlWrap .topInfo p{color:#161616;margin:0;font-size:1.2rem;line-height:2.2rem;}
.contactDtlWrap ul li{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;margin:0 0 15px;}
.contactDtlWrap ul li:nth-child(3){align-items:flex-start;}
.contactDtlWrap ul li .iconWrap{width:50px;height:50px;background-color:#ffffff;border-radius:8px;display:flex;align-items:center;justify-content:center;}
.contactDtlWrap ul li .dataWrap{width:calc(100% - 65px);}
.contactDtlWrap ul li .dataWrap h4{color:#333333;font-size:1.4rem;}
.contactDtlWrap ul li .dataWrap a{color:#333333;font-size:1.4rem;}
.contactDtlWrap ul li .dataWrap p{margin:0;color:#333333;font-size:1.4rem;line-height:2.6rem;}
.contactDtlWrap .socialWrap{padding:60px 0 0;}
.contactDtlWrap .socialWrap h3{color:#333333;font-size:1.2rem;}
.contactDtlWrap .socialWrap ul li a svg{width:18px;}
.contactDtlWrap .socialWrap ul li:last-child a svg{width:16px!important;}
.contactFormWrap{width:65%;padding:30px 40px 20px;}
ul.formList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
ul.formList li{width:100%;margin:0 0 30px;}
ul.formList li:last-child{margin:0;}
ul.formList li.half{width:48%;}
ul.formList li label{color:#131022;font-size:1.4rem;font-weight:500;width:100%;}
ul.formList li input,
ul.formList li textarea{border:1px solid rgba(34, 34, 34, 0.16);border-radius:8px;outline:0;box-shadow:none;height:46px;width:100%;padding:5px 15px;}
ul.formList li textarea{height:110px;}
ul.formList li .ctaWrap{text-align:left;}
ul.formList li .ctaWrap .btn{background-color:#161616;padding:12px 25px;border:0;border-radius:0;outline:0;box-shadow:none;color:#ffffff;font-size:1.5rem;letter-spacing:0.5px;}
.contactPInstaFeed{width:100%;margin:0 auto;padding:0 0 80px;background-color:#ffffff;}
.contactPInstaFeed .pad0{padding:0;}
.contactPInstaFeed .slick-slide.slick-active{opacity:1;}
.contactPInstaFeed .slick-slide{opacity:0.5;}
ul.contactPInstaSlider{margin:20px auto 0;}
ul.contactPInstaSlider li a .imgWrap{position:relative;}
ul.contactPInstaSlider li a .imgWrap .icoWrap{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);color:#ffffff;font-size:2.4rem;}
ul.contactPInstaSlider .slick-arrow{background-color:#ffffff;width:30px;height:30px;border-radius:100%;box-shadow:0px 1px 16px rgba(0, 0, 0, 0.16);color:transparent;position:absolute;top:50%;transform:translateY(-50%);z-index:9;}
ul.contactPInstaSlider .slick-arrow:after{content:"";position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-image:url(../img/arrowIco.png);background-position:center;background-repeat:no-repeat;width:12px;height:12px;background-size:contain;}
ul.contactPInstaSlider .slick-prev{left:60px;}
ul.contactPInstaSlider .slick-next{right:80px;}
ul.contactPInstaSlider .slick-next:after{transform:translate(-50%, -50%) rotate(-180deg);}
ul.formList li .formField{position:relative;}
ul.formList li .formField label span{color:#ff0000;}
.errorMsg{color:#ff0000!important;font-size:1.1rem!important;position:absolute;right:12px;bottom:-4px;background-color:#ffffff;padding:0 7px;margin:0;}

.footerWrap{width:100%;padding:30px 0 26px;margin:0 auto;background-color:#a4a4a4;position:relative;}
.brdBtm{border-bottom:1px solid rgba(255,255,255,0.2);padding:0 0 20px;}
.footerLogoWrap{text-align:left;margin:0 auto 24px;}
.footerLogoWrap a{display:inline-block;width:250px;}
.footerInfo p{color:#ffffff;font-size:1.4rem;line-height:2.6rem;margin:10px 0 16px;text-align:left;}
.socialWrap h3{color:#ffffff;font-size:1.5rem;margin:0 0 15px;text-align:left;line-height:2.6rem;}
.socialWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.socialWrap ul li{margin:0 10px 0 0;}
.socialWrap ul li:nth-child(5) a svg{width:18px;height:18px;}
.footerLink{display:flex;flex-flow:row wrap;justify-content:space-between;padding:0 30px;margin:0;}
.footerLink li{width:50%;margin:0 0 18px;text-align:left;}
.footerLink li a{color:#ffffff;font-size:1.4rem;}
.footerLink li:hover a{color:#FDE8E8;}
.footerContactDtl ul li{margin:0 0 25px;text-align:left;}
.footerContactDtl ul li h4{color:#ffffff;font-weight:400;font-size:1.4rem;line-height:2.6rem;}
.footerContactDtl ul li a{color:#ffffff;font-size:1.4rem;}
.footerContactDtl ul li p{color:#ffffff;font-size:1.4rem;margin:0;}
.footerContactDtl .socialWrap{display:none;}

/*---Editor Logo Remove----*/
.homeBannerWrap .dataWrap .descripWrap p p:last-of-type,
.trueStoryCotnent .dataWrap .leftData p p:last-of-type,
.blogDtlContent .dataWrap p p:last-of-type,
.relatedBlogWrap .dataWrap p p:last-of-type,
.lPostListing li .dataWrap p p:last-of-type,
.fashionWrap .instaFeeds h3 p:last-of-type,
.misVisWrap .dataWrap p p:last-of-type,
.aboutWrap .btmDataWrap p p:last-of-type,
.misVisWrap .dataWrap ul p:last-of-type,
.startWithBx1Wrap .dataWrap p p:last-of-type,
.singleBlogleft .itemWrap .dataWrap .descripWrap p p:last-of-type,
.startWithBx2Wrap .dataWrap p p:last-of-type{display:none!important;}
.blogDtlContent .dataWrap p p br,
.aboutWrap .btmDataWrap p p br,
.aboutWrap .btmDataWrap p br{display:none;}

.shopBannerWrap{width:100%;margin:0 auto;padding:20px 0 20px;}
.shopBannerWrap .carousel-item{width:100%;height:260px;overflow:hidden;position:relative;}
.shopBannerWrap .carousel-item img{height:100%;width:100%;object-fit:cover;}
.shopBannerWrap .carousel-caption{position:absolute;top:50%;max-width:360px;left:50px;transform:translateY(-50%);text-align:left;}
.shopBannerWrap .carousel-caption h2{font-size:3rem;font-weight:900;line-height:4.2rem;margin:0 0 26px;color:#161616}
.shopBannerWrap .carousel-caption a{display:inline-block;background-color:#161616;padding:8px 24px;color:#ffffff;font-weight:600;font-size:1.4rem;border-radius:30px;line-height:2.6rem;}
.shopBannerWrap .carousel-control-prev,
.shopBannerWrap .carousel-control-next{display:none;}
.shopPageProduct{padding:30px 70px 60px;background-color:#fafafa;}
.shopPageProduct .lPostListing li{padding:0 15px;}
.shopPageProduct .lPostListing li .shopthepost-widget{background-color:#ffffff;padding:20px 0 15px;}
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product .stp-product-link:hover:after{content:none!important;}
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product .stp-product-link:active, 
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product .stp-product-link:focus, 
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product  .stp-product-link:hover{text-decoration:none!important;}
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product .stp-price{font-family:'Lora', serif!important;font-weight:600!important;font-size:1.4rem!important;}
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product{margin:0 auto!important;width:100%!important;height:160px;}
.shopPageProduct .lPostListing li .shopthepost-widget .stp-product a.stp-product-link img,
.shopPageProduct .lPostListing li .shopthepost-widget #stp-6498824dd8e01 img{max-width:146px!important;max-height:160px!important;}


.paginationPgeWrap{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;padding:10px 0 60px;margin:0 auto;text-align:center;}
.paginationPgeWrap .btnPage{font-size:1.4rem;margin:0 4px;border-radius:30px;cursor:pointer;transition:all 0.3s ease;background:rgba(191, 191, 191, 1);color:#000000;border:0;box-shadow:none;outline:0;padding:6px 30px;line-height:2.6rem;}
.paginationPgeWrap .btnPage[disabled]{opacity:0.5;cursor:auto;}
.paginationWrap button{width:34px;height:34px;font-family:inherit;font-weight:600;font-size:1.6rem;margin:0 4px;border-radius:100%;cursor:pointer;transition:all 0.3s ease;background:rgba(191, 191, 191, 1);color:#000000;border:0;box-shadow:none;outline:0;}
.paginationWrap button.active{font-weight:900;background:#FDE8E8;}

.noteProduct{text-align:center;margin:30px auto 0;}
.noteProduct h2{background-color:#FDE8E8;padding:20px;margin:0 auto;}
.noProduct{color:rgba(22, 22, 22, 0.4);text-align:center;margin:0 auto;font-size:2.8rem;font-weight:700;}
.minHtNoBlog{min-height:30vh;display:flex;align-items:center;justify-content:center;}

.subCatBlogWrap{width:100%;margin:0 auto;padding:50px 70px 50px;position:relative;}
.subCatBlogWrap:after{content:"";background-image:url(../img/Vector.png);background-repeat:no-repeat;background-size:100%;background-position:center top;position:absolute;left:0;top:0;width:100%;height:100%;z-index:-1;opacity:0.6;}
.singleBlogleft .itemWrap .imgWrap{width:100%;height:390px;overflow:hidden;margin:0 auto 10px;border:8px solid #ffffff;}
.singleBlogleft .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;object-position:top center;}
.singleBlogleft .itemWrap .dataWrap span{color:#161616;font-size:1.1rem;letter-spacing:0.8px;text-transform:uppercase;font-family:'Be Vietnam Pro', sans-serif;}
.singleBlogleft .itemWrap .dataWrap h3{color:#333333!important;font-size:1.8rem!important;font-weight:700!important;margin:6px 0 8px!important;line-height:3rem!important;overflow:hidden!important;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;font-family:'Lora', serif!important;}
.singleBlogleft .itemWrap .dataWrap .descripWrap{overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;height:50px;}
.singleBlogleft .itemWrap .dataWrap .descripWrap p,
.singleBlogleft .itemWrap .dataWrap .descripWrap p span,
.singleBlogleft .itemWrap .dataWrap .descripWrap p h3,
.singleBlogleft .itemWrap .dataWrap .descripWrap p p,
.singleBlogleft .itemWrap .dataWrap .descripWrap p div,
.singleBlogleft .itemWrap .dataWrap .descripWrap p div div{font-size:1.4rem!important;color:#161616!important;line-height:2.6rem!important;text-overflow:ellipsis;font-family:'Lora', serif!important;background-color:transparent!important;border:0!important;margin:0!important;text-transform:none;letter-spacing:0;text-align:left!important;}
.singleBlogleft .itemWrap .dataWrap .descripWrap p  img{display:none;}
ul.verticalLicating{flex-flow:column;padding:0 0 0 26px;}
ul.verticalLicating li{width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;margin:0 0 16px;padding:0 0 16px;border-bottom:1px solid #ffffff;}
ul.verticalLicating li:last-child{padding:0;margin:0;border:0;}
ul.verticalLicating li .itemWrap{margin:0;}
ul.verticalLicating li .itemWrap .imgWrap{width:230px;height:152px;border:8px solid #ffffff;overflow:hidden;}
ul.verticalLicating li .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;object-position:center;}
ul.verticalLicating li .dataWrap{width:calc(100% - 245px);text-align:left;}
ul.verticalLicating li .dataWrap .descripWrap p,
ul.verticalLicating li .dataWrap .descripWrap p h3,
ul.verticalLicating li .dataWrap .descripWrap p span,
ul.verticalLicating li .dataWrap .descripWrap p div{text-align:left!important;}
.subCatBlogWrap .lPostListing li .itemWrap .imgWrap{border:8px solid #ffffff;}
.vlogDtlShop{padding:210px 70px 40px;}

/* .react-multi-carousel-list{justify-content:center;}
.react-multi-carousel-item{padding:0 15px;} */
ul.react-multi-carousel-track{margin:0 auto;}
ul.react-multi-carousel-track li:nth-child(odd) .itemWrap .imgWrap{border-radius:150px 0 0 0;}
ul.react-multi-carousel-track li:nth-child(even) .itemWrap .imgWrap{border-radius:0 0 150px 0;}
.react-multi-carousel-item .itemWrap{padding:0 20px;}
.react-multi-carousel-item .itemWrap .imgWrap{height:390px;width:100%;overflow:hidden;}
.react-multi-carousel-item .itemWrap .imgWrap img{object-fit:cover;width:100%;height:100%;}
.react-multi-carousel-item .itemWrap .dataWrap{padding:10px 0 0 0;}
.react-multi-carousel-item .itemWrap .dataWrap h3{color:#161616;font-size:1.4rem;font-weight:500;margin:0;line-height:2.6rem;}

.faqsPageWrap{width:100%;padding:60px 0 80px;margin:0 auto;position:relative;}
.faqsPageWrap:after{content:"";background-image:url(../img/Vector.png);background-repeat:no-repeat;background-size:cover;background-position:center;position:absolute;width:100%;height:80vh;top:100px;z-index:1;opacity:0.5;}
.faqsPageWrap .titleWrap{margin:0 auto 30px;}
.faqsPageWrap .accordion{position:relative;z-index:9;}
.faqsPageWrap .accordion-item{margin:0 0 16px;padding:20px;border:2px solid #f5f6f7!important;border-radius:10px!important;box-shadow:0px 0px 5px rgba(0,0,0,0.01);}
.faqsPageWrap h2.accordion-header button{padding:0;outline:0;border-radius:0;box-shadow:none;background:transparent;font-size:1.6rem;color:#000000;line-height:24px;}
.accordion-button:not(.collapsed)::after{background-image:var(--bs-accordion-btn-icon);}
.faqsPageWrap .accordion-button:after{color:#000000!important;}
.faqsPageWrap .accordion-body p{margin:10px 0 0;color:#666666;line-height:22px;}

.subscribePopup .modal-content{border:0;border-radius:0;background-color:#FDE8E8;}
.subscribePopup .modal-header{padding:0;border:0;position:absolute;right:-8px;top:-8px;}
.subscribePopup .modal-header .btn-close{opacity:1;color:#ffffff;background:transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FDE8E8%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/0.6em auto no-repeat;background-color:#000000;border-radius:100%;}
.subscribePopup .modal-body{padding:15px 15px 0;}
.subscribePopup .oldNavyWrap .emailDataWrap .dataWrap .inpuFieldWrap input{max-width:320px;width:100%;display:block;margin:0 auto;background-color:rgba(255,255,255,0.6);border:0;}
.subscribePopup .oldNavyWrap .alert-success{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:80%;animation:hideAnimation 0s ease 1.2s;animation-fill-mode:forwards;}
@keyframes hideAnimation{
    to{padding:0;height:0;width:0;visibility:hidden;margin:0;}
} 

ul.disclaimerList{padding:18px 0 0;margin:0;}
ul.disclaimerList li{color:#ffffff;/*font-family:'Quicksand', sans-serif;*/font-size:1.2rem;line-height:18px;text-align:center;}

.topbarWrap{position:relative;}
.topbarWrap ul{display:flex;align-items:center;justify-content:flex-end;flex-flow:row wrap;position:absolute;right:14px;top:14px}
.topbarWrap ul li{margin:0 0 0 6px;}
.topbarWrap ul li a,
.topbarWrap ul li button{display:flex;align-items:center;justify-content:center;width:24px;height:24px;border-radius:4px;background-color:#FDE8E8;cursor:pointer;position:relative;z-index:99;padding:0;border:0;box-shadow:none;outline:0;}
.topbarWrap ul li a svg,
.topbarWrap ul li button svg{width:16px!important;height:16px!important;}
.topbarWrap ul li a svg path,
.topbarWrap ul li button svg path{fill:#000000;}

.collaborateWrap .titleWrap p a{color:#000000;font-weight:500;}
.collaborateWrap .contactWrap{box-shadow:none;border-radius:0;}
.collaborateWrap .contactFormWrap{padding:0;width:100%;}


.aboutPageInstaFeed{width:100%;margin:0 auto;padding:50px 0 80px;background-color:#ffffff;}
.aboutPageInstaFeed .titleWrap{margin:0 auto 20px;}
.aboutPageInstaFeed .pad0{padding:0;}
.aboutPageInstaFeed .react-multi-carousel-item{margin:0 8px;}
.aboutPageInstaFeed a .imgWrap{position:relative;}
.aboutPageInstaFeed a .imgWrap img{width:100%;}
.aboutPageInstaFeed a .imgWrap .icoWrap{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);color:#ffffff;font-size:2.4rem;}
.aboutPageInstaFeed .react-multiple-carousel__arrow,
.aboutPageInstaFeed .react-multiple-carousel__arrow:hover{background-color:#ffffff;width:30px;height:30px;border-radius:100%;box-shadow:0px 1px 16px rgba(0, 0, 0, 0.16);color:#000000;z-index:9;}
.aboutPageInstaFeed .react-multiple-carousel__arrow::before{color:#000000!important;}



.search-container{position:relative;display:flex;align-items:center;}
.search-icon{cursor:pointer;padding:8px;}
.search-input-container{position:absolute;top:50%;left:50%;transform:translate(-104%, -50%);display:none;z-index:999;}
.search-input-container.active{display:block;}
.search-input-container input{width:238px;padding:8px;border:1px solid #e2e2e2;border-radius:4px;}
.search-results{position:absolute;top:100%;left:0;width:100%;background-color:#ffffff;box-shadow:0 2px 4px rgba(0, 0, 0, 0.1);padding:10px;display:flex;flex-direction:column;z-index:999;}
.search-results div{padding:4px;cursor:pointer;}
.search-results div:hover{background-color:#FDE8E8;}

.subscribedWrap{width:100%;background-color:#ffffff;margin:74px auto 0;padding:0 70px;}
.subscribedWrap .subscribedBox{background-color:#FDE8E8;padding:60px 60px;position:relative;border-radius:6px;}
.subscribedWrap .dataWrap .icoWrap{margin:0;position:absolute;top:15px;left:15px;}
.subscribedWrap .dataWrap .icoWrap svg{width:84px;height:84px;opacity:0.06;}
.subscribedWrap .dataWrap h3{margin:0;font-family:'Carattere', cursive;font-size:3.6rem;}
.subscribedWrap .inpuFieldWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.subscribedWrap .inpuFieldWrap input{border:0;padding:5px 10px;height:52px;width:calc(100% - 130px);}
.subscribedWrap .inpuFieldWrap button{width:130px;background-color:#000000;color:#ffffff;height:52px;font-size:1.4rem;text-transform:uppercase;letter-spacing:0.6px;}

.searchPageWrap{width:100%;background-color:#ffffff;margin:0 auto;padding:30px 70px 100px;}
.searchPageWrap .searchField{margin:0 auto 30px;}
.searchPageWrap .searchField form{display:flex;justify-content:center;max-width:520px;margin:0 auto;text-align:center;}
.searchPageWrap .searchField input{border:0;padding:5px 10px;height:52px;width:calc(100% - 130px);background-color:#f2f2f2;}
.searchPageWrap .searchField button{width:130px;background-color:#000000;color:#ffffff;height:52px;font-size:1.4rem;text-transform:uppercase;letter-spacing:0.6px;}
.searchPageWrap h2{text-align:center;margin:0 auto 20px;font-size:1.6rem;}
.searchPageWrap ul{margin:0;padding:0;}
.searchPageWrap ul li:not(:last-child){border-bottom:1px solid #f2f2f2;padding:0 0 10px;margin:0 0 10px;}
.searchPageWrap .searchResLink{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.searchPageWrap .leftImgWrap{width:120px;height:120px;overflow:hidden;}
.searchPageWrap .leftImgWrap img{width:100%;height:100%;object-fit:cover;} 
.searchPageWrap .rightDataWrap{width:calc(100% - 140px);}
.searchPageWrap .rightDataWrap h2{text-align:left;font-size:1.8rem;font-weight:600;color:#000000;margin:0 0 8px;font-family:'Lora', serif!important;}
.searchPageWrap .rightDataWrap h3{color:#161616;font-size:1.2rem;letter-spacing:0.2px;font-family:'Be Vietnam Pro', sans-serif!important;}
.searchPageWrap .rightDataWrap .descriptTxt{overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;height:70px;text-align:left!important;}
.searchPageWrap .rightDataWrap .descriptTxt p,
.searchPageWrap .rightDataWrap .descriptTxt p span#isPasted{text-overflow:ellipsis!important;margin:0!important;color:#666666!important;font-size:1.4rem!important;line-height:22px!important;font-family:'Lora', serif!important;text-align:left!important;}
.searchPageWrap .rightDataWrap .descriptTxt p a{color:#995757;}
.searchPageWrap .rightDataWrap .descriptTxt p img{display:none;}
.searchPageWrap .serachQueryTxt{text-align:center;font-size:1.6rem;margin:0;}
.searchPageWrap .serachQueryTxt span,
.searchPageWrap h2 span{color:#995757;}
.searchPageWrap .descriptTxt p:last-of-type{display:none!important;}
.searchPageWrap .descriptTxt .shopthepost-widget{display:none!important;}

.searchPageWrap .pagination{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;padding:30px 0 60px;margin:0 auto;text-align:center;}
.searchPageWrap .pagination button{width:34px;height:34px;font-family:inherit;font-weight:600;font-size:1.6rem;margin:0 4px;border-radius:100%;cursor:pointer;transition:all 0.3s ease;background:rgba(191, 191, 191, 1);color:#000000;border:0;box-shadow:none;outline:0;}
.searchPageWrap .pagination button.active{font-weight:900;background:#FDE8E8;}

.instaFeedWrap.vlogDtlShop .instaFeedBox h2{margin:0 auto 28px;text-align:center;color:#161616;font-size:2.4rem;line-height:3.2rem;}
.instaFeedWrap.vlogDtlShop .instaFeedBox ul.padRt{display:flex;flex-flow:row wrap;justify-content:space-between;background-color:transparent;margin:-130px auto 20px!important;}
.instaFeedWrap.vlogDtlShop .instaFeedBox ul.padRt li{width:48%;background-color:#ffffff;margin:0 auto 40px;}
.instaFeedWrap.vlogDtlShop .instaFeedBox ul.padRt li:nth-child(1),
.instaFeedWrap.vlogDtlShop .instaFeedBox ul.padRt li:nth-child(2){margin:0px auto;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe{padding:0px 50px 20px;margin:100px 0 0;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe ul.padRt{justify-content:flex-start;margin:-40px 0 0!important;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe ul.padRt li{width:94%;margin:0;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe .imgWrap{width:100%;position:relative;padding:0 80px 40px 0;margin:0 0 20px;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe .imgWrap  .topImg{margin:-300px 0 0;height:460px;overflow:hidden;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe .imgWrap  .btmImg{width:210px;position:absolute;bottom:0px;right:-50px;}
.instaFeedWrap.vlogDtlShop .instaFeedBox.foronlyOneStroe .imgWrap img{width:100%;height:100%;object-fit:cover;border:10px solid #ffffff;} 
.instaFeedWrap.vlogDtlShop .instaFeedBox.forMultipleFeeds{margin:0 0 180px;}
.instaFeedWrap.vlogDtlShop .lastGroup .instaFeedBox.forMultipleFeeds{margin:0;}

.highlight{background-color:#ffff00;font-weight:bold;}
.iframeOne #customForm .quick_form_18_css{border:0!important;}

.fashionWrapTwo.fashionWrap{padding:60px 140px 70px}
.fashionWrapTwo.fashionWrap .instaFeeds{padding:0 36px 0 0;}
.fashionWrapTwo.fashionWrap .imagesWrap .btmImg{margin:0 -45px -45px 0;z-index:99;}
.fashionWrapTwo.fashionWrap .imagesWrap{justify-content:flex-end;}


#root{overflow:hidden;}
.fr-dii{float:left!important;}
.forMobileDisply{display:none;}

.shopPageWidget{background-color:#ffffff;padding:40px 70px 80px;}
ul.shopWidgetList{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;padding:40px 0 0;}
ul.shopWidgetList li{width:48%;margin:0 auto 36px;position:relative;padding:14px;}
ul.shopWidgetList li:after{content:"";background-color:#FDE8E8;position:absolute;width:100%;height:68%;top:70px;z-index:-1;}
ul.shopWidgetList li h2{text-align:center;margin:0 auto 12px;text-transform:capitalize;}
ul.shopWidgetList .shopWidgetBoxWrap{padding:0 0 0 24px;margin:0 auto;}
ul.shopWidgetList .shopWidgetBoxWrap .shopWidgetBox{background-color:#ffffff;} 

.lPostListing li .itemWrap .imgWrap{position:relative;}
.lPostListing li .itemWrap .imgWrap .dateTxt{position:absolute;bottom:0px;background-color:#fee8e8;width:100%;margin:0 auto;padding:6px 10px;font-family:'Be Vietnam Pro', sans-serif;font-size:1.2rem;font-weight:500;color:#161616;text-align:center;}
.titleWrap h3.dateTxt{color:#161616;font-weight:400;font-size:1.6rem;margin:6px auto 0;}
#isPasted:empty{display:none!important;}

.quick_form_7_css,
#customForm .quick_form_7_css{background-color:transparent!important;}
#customForm > div{background-color:transparent!important;border:0!important;}
#iframewin #customForm input{border:1px solid #e0e0e0;}
