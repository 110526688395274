@media(min-width:2000px){
  .homeBannerWrap .imgWrap{width:100%;}
}
@media (min-width:768px){
    .position-md-absolute{position:absolute;margin:0 auto;padding:0;left:50%;transform:translateX(-50%);}
    .navbar-nav .nav-item:nth-child(3){margin-right:460px!important;}
  }
  @media(min-width:1640px){
    .blogDtlContent .blogImgListing li{width:32.33%;}
    .blogCatWrap .leftImgWrap .imgWrap{height:564px;}
    .blogCatBox .rightData ul li .itemWrap .imgWrap{height:252px;}
    .blogCatBox .leftData{width:42.55%;}
    .blogCatBox .rightData{width:calc(100% - 45%);}
  }
  @media(min-width:1600px){
    .aboutWrap .btmDataWrap{bottom:140px;}
  }
  @media(min-width:1366px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:206px;}
  }
  @media(min-width:1400px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:212px;}
  }
  @media(min-width:1420px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:220px;}
  }
  @media(min-width:1440px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:221px;}
  }
  @media(min-width:1450px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:224px;}
  }
  @media(min-width:1470px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:227px;}
  }
  @media(min-width:1485px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:229px;}
  }
  @media(min-width:1500px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:225px;}
  }
  @media(min-width:1524px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:231px;}
  }
  @media(min-width:1545px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:235px;}
  }
  @media(min-width:1555px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:238px;}
  }
  @media(min-width:1567px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:239px;}
  }
  @media(min-width:1567px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:239px;}
  }
  @media(min-width:1600px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:245px;}
  }
  @media(min-width:1628px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:251px;}
  }
  @media(min-width:1638px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:253px;}
  }
  @media(min-width:1500px){
    .bigScreenWid{max-width:1500px;}
    .aboutWrap .imgWrap img,
    .startWithBx2Wrap .imgWrap img,
    .partnersWrap .imgWrap img{width:100%;object-fit:cover;}
    .startWithBx2Wrap:after{min-height:350px;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:40px 30px 50px 30px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 170px;}
    .partnersWrap .itemWrap.marBtm{margin-bottom:43px;}
    .homeBannerWrap .imgWrap{width:780px;height:760px;}
  }
  @media(max-width:1639px){
    html{font-size:62%;}
    .blogDtlContent .blogImgListing li{width:36.33%;}
  }
  @media(max-width:1600px){
    html{font-size:62%;}
    .aboutWrap .btmDataWrap{bottom:106px;}
  }
  @media(max-width:1599px){
    .aboutWrap .btmDataWrap{bottom:90px;}
    .partnersWrap .itemWrap .imgWrap img{width:100%;}
  }
  @media(max-width:1560px){
    .startWithBx2Wrap:after{min-height:290px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 100px;}
  }
  @media(max-width:1480px){
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 30px 50px 30px;}
    .partnersListing li{margin:0 16px;}
    .aboutWrap .btmDataWrap{bottom:60px;}
  }
  @media(max-width:1399px){
    .aboutWrap .btmDataWrap{bottom:0px;}
    /* .aboutWrap .topDataWrap{padding:80px 28px 10px;} */
    .fashionWrap .instaFeeds p{bottom:-50px;}
  }
  @media(max-width:1350px){
    .blogDtlContent .blogImgListing li{width:40.33%;}
  }
  @media(max-width:1280px){
    html{font-size:54%;}
    .favoritesWrap ul li .favItem .imgWrap img{height:210px;}
    .favoritesWrap ul li:nth-child(1) .topImgWrap img{height:350px;}
    .favoritesWrap ul li.quoteTxt .favDataDesc{height:350px;padding:30px;}
    .favoritesWrap ul li .bgClrtxt{height:350px;}
    .trueStoryCotnent .dataWrap{padding:0 0 0 20px;}
    .homeBannerWrap .dataWrap{right:-80px;}
    /* .aboutWrap .topDataWrap{padding:80px 28px 10px;} */
    .lPostListing li .itemWrap .imgWrap{height:250px;}
    .lPostListing li .dataWrap .descripWrap{height:48px;}
    .relatedBlogWrap .dataWrap .descriptionWrap{height:38px;}
    .homeBannerWrap .dataWrap p{line-height:2.8rem!important;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 30px 50px 60px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 120px 60px;}
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:189px;}
  }
  @media(max-width:1240px){
    .aboutWrap{padding:20px 30px 0;}
    .latestPostWrap{padding:40px 30px;}
    .blogDtlWrap{padding:20px 30px 120px;}
    .instaFeedWrap{padding:120px 30px 40px;}
    /* .startWithBx1Wrap{padding:100px 30px;} */
    .startWithBx2Wrap{padding:0 30px;}
    .homeBannerWrap{padding:40px 30px 60px;}
    .partnersWrap{padding:0 30px 80px;}
    .fashionWrap{padding:0 70px 70px;}
    .fashionWrap:after{width:calc(100% - 70px);left:35px;}
    .livingWrap{padding:80px 30px 60px 30px;}
    .pickUpWrap{padding:20px 60px 80px;}
    .trueStoryWrap{padding:60px 30px 0;}
    .favoritesWrap{padding:60px 30px;}
    .blogCatWrap{padding:60px 30px 50px;}
    .startWithBx1Wrap{padding:0px 30px 20px;}
    .fashionWrapTwo.fashionWrap{padding:60px 70px 70px;}
  }
  @media(max-width:1200px){
    .trueStoryWrap{padding:60px 30px 0;}
  }
  @media(max-width:1199px){
    .contactDtlWrap{width:42%;}
    .contactFormWrap{width:58%;}
    .blogDtlContent .blogImgListing{padding:0 50px 0 0;}
    .blogDtlContent .blogImgListing li{width:36.33%;}
    .blogDtlContent .blogImgListing li:nth-child(1), 
    .blogDtlContent .blogImgListing li:nth-child(4), 
    .blogDtlContent .blogImgListing li:nth-child(7){margin:0 -60px 0 0;}
    .blogDtlContent .blogImgListing li:nth-child(2), 
    .blogDtlContent .blogImgListing li:nth-child(5), 
    .blogDtlContent .blogImgListing li:nth-child(8){margin:30px -30px 30px 0;}
    .blogDtlContent .blogImgListing li:nth-child(3), 
    .blogDtlContent .blogImgListing li:nth-child(6), 
    .blogDtlContent .blogImgListing li:nth-child(9){margin:0px 0 0 -5px;}
    .blogDtlContent .blogImgListing .imgWrap{width:300px;height:330px;}
    .homeBannerWrap .dataWrap{max-width:320px;}
    .homeBannerWrap .imgWrap{width:590px;height:590px;}
    .homeBannerWrap .rightImgWrap{width:280px;height:310px;}
  }
  @media(max-width:1140px){
    .aboutWrap .btmDataWrap{bottom:-30px;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding: 20px 30px 50px 80px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 55px 80px;}
    .startWithBx2Wrap:after{min-height:240px;}
    .partnersListing li{margin:0 5px;}
    .blogDtlContent .blogImgListing{padding:0 80px 0 0;}
    .startWithBx2Wrap:after{min-height:240px;}
    .startWithBx2Wrap .abtTtl{padding:0;}
    .aboutWrap .flexOr-1 .imgWrap{width:44%;}
  }
  @media(max-width:1070px){
    .contactDtlWrap{padding:30px 20px;}
    .contactFormWrap{padding:30px 20px 20px;}
    .instaFeedWrap{padding:120px 20px 40px;}
    .aboutWrap .btmDataWrap{bottom:-20px;}
    .relatedBlogWrap .dataWrap h3{line-height:2rem;}
    .aboutWrap .btmDataWrap{bottom:-60px;left:-110px;}
    /* .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 30px;} */
    .relatedBlogWrap .dataWrap .descriptionWrap{height:34px;}
    .aboutWrap .flexOr-1 .imgWrap{width:40%;margin:0 20px 10px 0;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 30px 20px 96px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 20px 55px 96px;}
  }
  @media(max-width:1020px){
    .fashionWrap{padding:0 30px 70px;}
    .fashionWrapTwo.fashionWrap{padding:60 30px 70px;}
    .fashionWrap:after{width:calc(100% - 0px);left:0px;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 10px 50px 100px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 10px 55px 100px;}
  }
  @media(max-width:1024px){
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:155px;}
  }
  @media(max-width:991px){
    .contactPageWrap{padding:30px 30px 60px;}
    .navbar-expand-md .navbar-nav .nav-item{margin:0 10px!important;}
    .navbar-nav .nav-item:nth-child(3){margin-right:320px!important;}
    .footerWrap{padding:30px 20px 40px;overflow:hidden;}
    .footerWrap .container{max-width:100%;}
    .footerLink{padding:0;}
    .topVector, 
    .BtmVector{display:none;}
    .lPostListing li{width:33.33%;}
    .blogDtlContent .blogImgListing li:nth-child(1), 
    .blogDtlContent .blogImgListing li:nth-child(4), 
    .blogDtlContent .blogImgListing li:nth-child(7){margin:0 -38px 30px 0;}
    .blogDtlContent .blogImgListing li:nth-child(2), 
    .blogDtlContent .blogImgListing li:nth-child(5), 
    .blogDtlContent .blogImgListing li:nth-child(8){margin:40px -30px 0 0;}
    .shopBannerWrap{padding:20px 30px;}
    .shopBannerWrap .container{max-width:100%;}
    /* .aboutWrap .topDataWrap{padding:40px 16px 10px;} */
    .aboutWrap .btmDataWrap p{line-height:2.2rem;}
    .aboutWrap .topDataWrap h4{line-height:2.4rem;}
    /* .favoritesWrap ul li:nth-child(1), 
    .favoritesWrap ul li.bigWid{width:40%;} */
    .favoritesWrap ul{justify-content:flex-start;}
    .favoritesWrap ul li{width:33.33%;}
    .favoritesWrap ul li.quoteTxt .favDataDesc{min-height:360px;}
    .partnersListing li .imgWrap{width:140px;}
    .partnersListing li{width:auto!important;}
    .partnersWrap .itemWrap.marBtm{margin-bottom:18px;}
    .homeBannerWrap{padding:40px 15px 60px;}
    .partnersWrap{padding:0 15px 80px;}
    .fashionWrap{padding:0 15px 70px;}
    .fashionWrapTwo.fashionWrap{padding:60px 15px 70px;}
    .livingWrap{padding:80px 15px 60px;}
    .pickUpWrap{padding:20px 15px 80px;}
    .trueStoryWrap{padding:40px 15px 0;}
    .favoritesWrap{padding:60px 15px;}
    .fashionWrap .fasionContent{padding:0;}
    .homeBannerWrap .dataWrap .descripWrap>p{height:80px;}
    .homeBannerWrap .dataWrap p{margin:12px 0 50px;line-height:2.4rem;}
    .homeBannerWrap .dataWrap h2{line-height:3.2rem}
    .fashionWrap .fasionContent h2,
    .livingWrap .topDataWrap h2{font-size:3rem;line-height:4rem;}
    .livingWrap .leftPanel .socialShareWrap{padding:80px 0 0 30px;}
    .trueStoryCotnent .dataWrap .rightImg{margin:0 0 -30px;}
    ul.verticalLicating li .itemWrap .imgWrap{width:210px;height:140px;}
    ul.verticalLicating li .dataWrap{width:calc(100% - 210px);}
    .blogCatWrap .leftImgWrap .imgWrap{height:420px;}
    .blogCatBox .rightData ul li .itemWrap .imgWrap{height:180px;}
    .blogDtlWrap{padding:20px 15px 120px;}
    .blogDtlContent .blogImgListing li:nth-child(1), 
    .blogDtlContent .blogImgListing li:nth-child(4), 
    .blogDtlContent .blogImgListing li:nth-child(7){margin:15px -38px 30px 0;}
    .blogDtlContent .blogImgListing li:nth-child(3), 
    .blogDtlContent .blogImgListing li:nth-child(6), 
    .blogDtlContent .blogImgListing li:nth-child(9){margin:15px 0 0 -5px;}
    .blogDtlContent .blogImgListing .imgWrap{width:280px;height:310px;}
    .headerWrap .navbar-brand{width:286px;}
    .headerWrap .navbar{height:100px;}
    .homeBannerWrap .dataWrap{max-width:270px;}
    .homeBannerWrap .imgWrap{width:530px;height:530px;}
    .homeBannerWrap .rightImgWrap{width:230px;height:280px;}
    .oldNavyWrap .emailDataWrap .dataWrap h3{width:100%;}
    .partnersListing li{margin:0 1px;}
    .startWithBx1Wrap{padding:30px 30px 40px;}
    /* .aboutWrap .flexOr-1 .imgWrap{width:44%;} */
    .startWithBx2Wrap .imgWrap{width:320px;height:400px;}
    .fashionWrap .imagesWrap .topImg{width:405px;height:410px;}
    .fashionWrap .imagesWrap .btmImg{width:205px;height:210px;}
    .livingWrap .leftPanel .topImgsWrap .img1{width:300px;height:330px;}
    .aboutWrap .imgWrap2{height:450px;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 10px 20px 40px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 10px 55px 40px;}
    .topbarWrap ul{top:8px;right:8px;}
    #blogDetails.blogDtlContent .dataWrap img, .blogDtlContent .dataWrap p img{width:100%!important;}
    .ctasWrap{margin:0 auto 30px;}
    .blogDtlContent .dataWrap{width:100%;}
  }
  @media(max-width:959px){
    .aboutWrap .col-6.flexOr-1,
    .aboutWrap .col-6.flexOr-2{width:50%;}
    .aboutWrap .col-6.flexOr-1 .imgWrap{width:100%;}
    .forMobileDisply{display:inline-block;}
    .mobDisNone{display:none;}
    .aboutWrap .imgWrap2,
    .aboutWrap .col-6.flexOr-1 .imgWrap{height:485px;}
    .aboutWrap .imgWrap2 img,
    .aboutWrap .col-6.flexOr-1 .imgWrap img{width:100%;height:100%;object-fit:cover;}
    .startWithBx1Wrap .col-md-8.flexOr-2{width:100%;}
    .startWithBx1Wrap{padding:0px 30px 40px;margin:0;}
    .startWithBx1Wrap .abtTtl{padding:0 0 10px 0;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:0px 0px 20px 80px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 0px 55px 80px;}
  }
  @media(max-width:810px){
    /* .aboutWrap .topDataWrap{padding:16px 16px 10px;} */
    .partnersListing li .imgWrap{width:140px;}
    .livingWrap .leftPanel .socialShareWrap{padding:80px 0 0 10px;}
    .trueStoryCotnent{padding:30px;}
    .blogDtlContent .blogImgListing{padding:0 30px 0 0;}
    .blogDtlContent .blogImgListing .imgWrap{width:242px;height:270px;}
    .homeBannerWrap .dataWrap{max-width:250px;padding:15px 15px 30px;}
    .homeBannerWrap .imgWrap{width:480px;height:480px;}
    .homeBannerWrap .rightImgWrap{width:210px;height:240px;}
    .headerWrap .navbar-brand{width:240px;}
    .navbar-nav .nav-item:nth-child(3){margin-right:260px!important;}
    .headerWrap .navbar-nav .dropdown-menu{left:-48px;}
    .aboutWrap .flexOr-1 .imgWrap{width:34%;margin:0 14px 10px 0;}
    .startWithBx2Wrap:after{bottom:30px;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:0px 0px 20px 110px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:30px 0px 55px 110px;}
  }
  @media(max-width:767px){
    .headerWrap{padding:0 20px 0 10px;}
    .headerWrap .navbar-brand{align-self:flex-start!important;}
    .navbar-toggler{padding:0;align-self:flex-end;border:0!important;border-radius:0!important;margin:15px 0 0;}
    .navbar-toggler-icon{width:34px;height:34px;}
    .offcanvas-header{background-color:#FFE5E5;border-bottom:1px dashed #ffffff;}
    .offcanvas-body{background-color:#FDE8E8;padding:0;}
    .offcanvas-title{font-weight:700!important;font-size:2.6rem!important;}
    .nav-link{font-size:1.6rem;padding:12px 15px;border-bottom:1px dashed #fafafa;}
    .navbar-nav .nav-item:nth-child(3){margin:0!important;}
    .navbar-nav .dropdown-menu{background-color:#FFF6F6;box-shadow:none!important;border:0!important;padding:0;}
    .dropdown-item{padding:10px 15px;font-size:1.4rem;border-bottom:1px dashed #FFE5E5;}
    .dropdown-item:focus, .dropdown-item:hover{background-color:transparent;}
    .contactWrap{flex-flow:column-reverse;}
    .contactDtlWrap{width:100%;}
    .contactDtlWrap .socialWrap{padding:20px 0 0;}
    .contactFormWrap{width:100%;padding:20px 10px 30px;}
    .footerWrap{padding:30px 20px 20px;}
    .footerLogoWrap,
    .footerInfo p{text-align:center;}
    .socialWrap{display:none;} 
    .footerContactDtl .socialWrap{display:inline-block;margin:10px 0 0;}
    .footerLink{padding:14px 0 5px;justify-content:center;border-top:1px solid #464646;border-bottom:1px solid #464646;margin:0 auto 15px;}
    .footerLink li{width:auto;display:inline-block;margin:0 10px 12px;}
    .footerContactDtl ul li{margin:0 0 6px;display:flex;align-items:center;flex-flow:row wrap;}
    .footerContactDtl ul li h4{font-weight:600;line-height:2.2rem;margin:0 10px 5px 0;border-bottom:1px solid #ffffff;}
    .footerContactDtl ul li p{margin:0 0 5px;}
    .footerContactDtl .socialWrap h3{margin:0 0 10px;font-weight:800;}
    .footerContactDtl .socialWrap ul li{margin:0 10px 0 0;}
    .lPostListing li{width:50%;}
    .shopPageProduct .lPostListing li{width:33.33%;}
    .instaFeedBox{padding:0 20px 20px;}
    .instaFeedBox h2{min-height:60px;}
    .blogDtlWrap{padding:20px 30px 30px;}
    .blogDtlWrap .mainImgWrap{height:320px;}
    /* .aboutWrap .topDataWrap{padding:16px 0 10px;} */
    .aboutWrap .btmDataWrap{position:relative;bottom:auto;left:auto;padding:0;}
    /* .aboutWrap .imgWrap2{padding:30px 0 0;} */
    /* .flexOr-1{order:1;} */
    /* .flexOr-2{order:2;} */
    /* .flexOr-3{order:3;} */
    .startWithBx1Wrap{padding:40px 30px;}
    .startWithBx1Wrap .imgWrap{text-align:center;margin:0 auto 15px;}
    .startWithBx2Wrap .imgWrap{text-align:center;}
    .startWithBx2Wrap .dataWrap.dataWrapTop{padding:20px 0px 10px 0px;}
    .startWithBx2Wrap .dataWrap.dataWrapBtm{padding:0px 0px 30px;}
    .misVisWrap{padding:20px 30px;}
    .misVisWrap .contentWrap2{padding:0;}
    .startWithBx1Wrap .dataWrap,
    .misVisWrap .contentWrap1{padding:0;}
    .trueStoryCotnent .imgWrap{margin:0 auto 10px;text-align:center;}
    .trueStoryCotnent .dataWrap .leftData p{margin:20px 0 30px;}
    .homeBannerWrap .dataWrap{position:relative;top:auto;left:auto;right:auto;bottom:auto;margin:0;border:0;max-width:100%;}
    .homeBannerWrap .row .col-md-3.padrt0{order:3;padding:0;}
    .homeBannerWrap .dataWrap p{margin:10px 0 25px!important;}
    .padRightInc{padding:0 5px;margin:0 0 15px;}
    .padLeft0{padding:0 5px;}
    .partnersWrap .itemWrap.marBtm{margin-bottom:15px;}
    .fashionWrap .imagesWrap,
    .livingWrap .leftPanel .topImgsWrap{justify-content:center;}
    .fashionWrap .padlt0{padding:0 10px;}
    .fashionWrap .fasionContent{margin:40px 0 0;}
    .fashionWrap .instaFeeds{padding:0;margin:20px auto 0;}
    .livingWrap .leftPanel .socialShareWrap{display:none;}
    .livingWrap .leftPanel:after{width:calc(100% + 42px);left:-22px;}
    ul.verticalLicating{padding:20px 0 0;}
    .blogCatWrap .leftImgWrap .imgWrap{height:362px;}
    .blogCatBox .rightData ul li .itemWrap .imgWrap{height:150px;}
    .blogCatWrap{padding:30px 30px 30px;}
    .blogCatWrap .ctaWrap{padding:30px 0 0;}
    .blogCatDtlWrap .blogCatList li{width:33.33%;margin:0;}
    .blogCatDtlWrap .blogCatList li:nth-child(even){margin:auto;}
    .blogCatDtlWrap .blogCatList li:nth-child(2),
    .blogCatDtlWrap .blogCatList li:nth-child(5),
    .blogCatDtlWrap .blogCatList li:nth-child(8),
    .blogCatDtlWrap .blogCatList li:nth-child(11){margin:-35px 0 0!important;}
    .blogDtlContent .blogImgListing{padding:0 15px 0 0;}
    .topbarWrap{background-color:#fee8e8;}
    .topbarWrap ul li{margin:0 0 0 2px;}
    .topbarWrap ul{position:relative;top:auto;right:auto;}
    .homeBannerWrap{padding:12px 15px 24px;}
    .partnersListing{padding:24px 0 30px;}
    .partnersWrap{padding:0 15px 30px;}
    .livingWrap{padding:30px 15px;}
    .pickUpWrap{padding:20px 15px 30px;}
    .trueStoryCotnent .imgWrap{max-width:40%;margin:0 auto 20px;text-align:center;}
    .oldNavyWrap{margin:20px auto 0;}
    .favoritesWrap{padding:30px 15px;}
    .searchPageWrap{padding:30px 15px 100px;}
    .aboutWrap .flexOr-1 .imgWrap{width:auto;float:none;margin:0;}
    .aboutWrap .flexOr-1 .contentWrap{display:none;}
    .startWithBx2Wrap .imgWrap{width:auto;height:auto;}
    .startWithBx2Wrap:after{bottom:0;min-height:400px;}
    .latestPostWrap{padding:0px 30px;}
    .blogDtlWrap .tagList li{margin:0 6px 12px;}
    .blogDtlWrap .tagList li a{padding:12px 16px;}
    .relatedBlogWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
    .relatedBlogWrap ul li{width:49%;}
    .homeBannerWrap .rightImgWrap{width:268px;height:300px;}
    .fashionWrapTwo.fashionWrap .row{flex-flow:column-reverse;}
    .fashionWrapTwo.fashionWrap .instaFeeds{padding:0;}
    .favoritesWrap ul li{width:50%;}
    .paginationWrap button{margin:0 4px 8px;}
    .partnersWrap .itemWrap.imgRightWrap .imgWrap{height:auto;}
  }
  @media(max-width:670px){
    .homeBannerWrap .rightImgWrap{width:230px;height:250px;}
  }
  @media(max-width:639px){
    .lPostListing li .itemWrap .imgWrap{height:230px;}
    /* .favoritesWrap ul li:nth-child(1), 
    .favoritesWrap ul li.bigWid,
    .favoritesWrap ul li{width:49%;}
    .favoritesWrap ul li:nth-child(1) .topImgWrap .imgData{padding:80px 20px 20px;} */
    .blogCatBox .leftData{width:100%;margin:0 auto 20px;}
    .blogCatBox .rightData{width:100%;}
    .blogCatWrap ul li{margin:0 auto 20px;}
    .blogCatDtlWrap .blogCatList li{width:50%;}
    .blogCatDtlWrap .blogCatList li:nth-child(2),
    .blogCatDtlWrap .blogCatList li:nth-child(5),
    .blogCatDtlWrap .blogCatList li:nth-child(8),
    .blogCatDtlWrap .blogCatList li:nth-child(11){margin:auto!important;}
    .blogCatDtlWrap .blogCatList li:nth-child(even){margin:-35px 0 0!important;}
    .blogDtlContent .blogImgListing .imgWrap{width:200px;height:230px;}
    .aboutWrap .imgWrap2, 
    .aboutWrap .col-6.flexOr-1 .imgWrap{height:370px;}
    .startWithBx2Wrap .dataWrap h2,
    .aboutWrap .topDataWrap h2{padding:0 100px 0 0;}
    .aboutWrap .topDataWrap h2:after,
    .startWithBx2Wrap .dataWrap h2:after,
    .startWithBx1Wrap .abtTtl h2:after{width:90px;}
    .startWithBx1Wrap .abtTtl h2{padding:0 0 0 100px;}
    .startWithBx1Wrap{padding:10px 30px 30px;}
  }
  @media(max-width:620px){
  
  }
  @media(max-width:575px){
    ul.formList li.half{width:100%;}
    .contactPageWrap{padding:30px 15px 30px;}
    .latestPostWrap{padding:0px 15px;}
    .blogDtlWrap{padding:20px 10px 30px;}
    .instaFeedBox{padding:20px;}
    .instaFeedBox h2{min-height:1px;margin:0px auto 10px;}
    .instaFeedBox ul.padLt,
    .instaFeedBox ul.padRt{margin:0 auto;}
    .instaFeedWrap{padding:10px 20px 40px;}
    .instaFeedBox ul{margin-bottom:30px!important;}
    .shopBannerWrap .carousel-caption h2{font-size:2.6rem;line-height:3.4rem;}
    .trueStoryCotnent .dataWrap .rightImg{display:none;}
    .trueStoryCotnent .dataWrap .leftData{width:100%;}
    .homeBannerWrap .padrt0{padding:0;}
    .blogCatWrap{padding:30px 15px;}
    .blogDtlContent .blogImgListing{margin:15px 0 0 -20px;}
    .blogDtlContent .blogImgListing .imgWrap{width:170px;height:200px;}
    .headerWrap .navbar-brand{width:220px;}
    .homeBannerWrap .imgWrap{width:380px;height:400px;}
    .homeBannerWrap .rightImgWrap{width:160px;height:180px;}
    ul.shopWidgetList li{width:100%;}
  }
  @media(max-width:459px){
    .favoritesWrap ul{width:100%;}
    /* .favoritesWrap ul li:nth-child(1), 
    .favoritesWrap ul li.bigWid, .favoritesWrap ul li{width:100%;} */
    .preloader-inner{width:100%;}
    .preloader-inner .loading-text{font-size:2.4rem;}
    .modal-dialog{max-width:90%!important;margin:0 auto;}
    /* .favoritesWrap ul li:nth-child(1) .topImgWrap img{height:290px;} */
    /* .favoritesWrap ul li.quoteTxt .favDataDesc{height:auto;min-height:1px;} */
    /* .favoritesWrap ul li .bgClrtxt{height:auto;min-height:1px;} */
    .favoritesWrap ul li .bgClrtxt .btmDataWrap{padding:20px 10px;}
    .searchPageWrap .searchField button{width:80px;}
    .searchPageWrap .searchField input{width:calc(100% -80px);}
    .aboutPageInstaFeed{padding:20px 0 40px;}
  }
  @media(max-width:419px){
    .lPostListing li{width:100%;}
    .shopPageProduct .lPostListing li{width:50%;padding:0 10px;margin:0 0 20px;}
    /* .startWithBx2Wrap .abtTtl{padding:0 110px 0 0;} */
    .startWithBx1Wrap .abtTtl{padding:0;}
    .startWithBx1Wrap .abtTtl span{padding:0 0 0 110px;}
    .shopBannerWrap{padding:20px 6px;}
    .startWithBx1Wrap .abtTtl span:after{width:100px;right:110px;left:auto;}
    .startWithBx2Wrap .abtTtl:after{width:100px;left:190px;}
    .pickUpWrap{padding:20px 0 80px;}
    .trueStoryWrap{padding:0;}
    .favoritesWrap{padding:40px 0 60px;}
    .pickUpFilter li a, .pickUpFilter li .nav-link{padding:10px 16px;}
    .partnersListing li .imgWrap{width:120px;}
    .homeBannerWrap{padding:15px 15px 50px;}
    .partnersListing{padding:30px 0;}
    .partnersWrap{padding:0 15px 50px;}
    .livingWrap{padding:40px 15px;}
    .oldNavyWrap .emailDataWrap .dataWrap h3{max-width:80%;}
    .misVisWrap{margin:50px auto;}
    .aboutWrap{padding:20px 15px 0;}
    .startWithBx1Wrap{padding:10px 15px 30px;}
    .startWithBx2Wrap{padding:0 15px;}
    .misVisWrap{padding:20px 15px;}
    .contactPageWrap{padding:30px 6px;}
    .latestPostWrap{padding:16px 8px 30px;}
    ul.verticalLicating li{flex-flow:column;}
    .subCatBlogWrap .lPostListing li .itemWrap .imgWrap{width:100%;height:200px;}
    ul.verticalLicating li .dataWrap{width:100%;}
    ul.verticalLicating{margin:0 auto;}
    .blogCatWrap{padding:30px 0;}
    .instaFeedWrap{padding:10px 6px 20px;}
    .blogCatDtlWrap .blogCatList{margin:0;}
    .blogCatDtlWrap .blogCatList li{padding:0 4px;}
    .blogDtlContent .blogImgListing .imgWrap{width:142px;height:150px;}
    .homeBannerWrap .imgWrap{width:300px;height:320px;}
    .homeBannerWrap .rightImgWrap{width:140;height:160px;}
    .aboutWrap .imgWrap2, 
    .aboutWrap .col-6.flexOr-1 .imgWrap{height:260px;}
    .relatedBlogWrap ul li{width:100%;}
  }
  @media(max-width:370px){
    .subscribePopup .oldNavyWrap h2{margin:0 auto 8px;}
    .subscribePopup .oldNavyWrap .emailDataWrap{margin:14px auto 0;}
    .subscribePopup .oldNavyWrap .imgWrap{height:220px;overflow:hidden;}
    .favoritesWrap ul li{width:100%;}
    .favoritesWrap ul li.quoteTxt .favDataDesc{height:auto;min-height:1px;} 
    .favoritesWrap ul li .bgClrtxt{height:auto;min-height:1px;} 
  }
  @media(max-width:359px){
    .startWithBx2Wrap .abtTtl:after{content:none;}
    .startWithBx2Wrap .abtTtl{padding:0;}
    .headerWrap .navbar-brand{width:220px;}
    .homeBannerWrap .imgWrap{width:280px;height:300px;}
    .homeBannerWrap .rightImgWrap{width:120px;height:140px;}
  }